{
  "pageTitle": {
    "generic": "Forest",
    "login": "ログイン",
    "forgotPassword": "パスワードを忘れましたか？",
    "resetPassword": "パスワード設定",
    "employeeCalendar": "カレンダー",
    "printEmployeeCalendar": "予約作成",
    "allSalons": "全てのサロン",
    "patientSearch": "検索",
    "roomSchedules": "部屋",
    "workShift": "勤務時間",
    "shipment": "発送リスト",
    "printShipment": "印刷する",
    "printLabels": "ラベル印刷",
    "printOrders": "印刷する",
    "unpaidOrders": "確認待ちのお薬/お支払い",
    "shippedMedicines": "週間・お渡し/発送済み個数",
    "orderStatisticsPerDay": "ご来院売上集計",
    "trackingNumberRequested": "追跡番号依頼リスト",
    "trackingNumberSent": "発送リスト",
    "addNewPatient": "新規患者様登録",
    "bloodAppointments": "本日の採血予定リスト",
    "patientRegistration": "新規申込(DB)",
    "patientDetails": "患者様情報",
    "termsOfService": "同意書",
    "createAppointment": "予約作成",
    "editAppointment": "編集する",
    "medicalRecord": "カルテ",
    "bloodExamination": "血液検査",
    "ordersAndPrescriptionsList": "配送依頼と処方リスト",
    "interviewSheet": "問診票",
    "mediaLibrary": "メディアライブラリ",
    "appointmentImages": "写真",
    "callScreen": "テレビ電話",
    "chief": "チーフマネージャー",
    "counselors": "カウンセラー",
    "newCounselor": "新規カウンセラーを作成",
    "editCounselor": "カウンセラー情報",
    "concierges": "コンシェルジュ",
    "newConcierge": "新規コンシェルジュを作成",
    "editConcierge": "コンシェルジュ情報",
    "clinicManagers": "クリニックマネージャー",
    "newClinicManager": "新規クリニックマネージャー",
    "editClinicManager": "クリニックマネージャーの編集",
    "doctors": "医師",
    "newDoctor": "医師追加",
    "editDoctor": "医師情報",
    "medicalTeam": "医療班",
    "newMedicalTeam": "新規メンバーを作成する",
    "editMedicalTeam": "医療班者情報",
    "viewers": "閲覧専用",
    "newViewer": "新規メンバーを作成する",
    "editViewer": "閲覧者アカウント情報",
    "medicines": "薬",
    "newMedicine": "薬の新規作成",
    "editMedicine": "お薬編集",
    "nonWorkingDay": "休診日です",
    "newNonWorkingDay": "休診日を作成",
    "editNonWorkingDay": "休診日の編集",
    "workShifts": "勤務シフト",
    "notifications": "通知",
    "rooms": "部屋",
    "newRoom": "部屋の追加",
    "editRoom": "部屋の編集",
    "coupons": "クーポン",
    "incomeStats": "総計売り上げ",
    "clinics": "院"
  },
  "calendarMode": {
    "calendarIsInEditMode": "カレンダー編集モード:",
    "timeDividerAutomatic": "時間枠を作成",
    "timeDividerManual": "時間枠を作成",
    "timeBlock": "時間をブロック",
    "unavailableForMobileAppointments": "アプリB"
  },
  "timeBlockType": {
    "ClinicAppointmentsOnlyBlockTime": "来院ブロック",
    "BlockTime": "ブロック",
    "OnlineAppointmentsOnlyBlockTime": "オンライン予約のみ",
    "FirstVisitBlockTime": "初診",
    "LunchBreak": "休憩"
  },
  "reservationType": {
    "TimeDivider": "時間枠を作成"
  },
  "patient": {
    "searchForPatient": "検索してください",
    "patientsList": "新規予約",
    "resultsFound": "件見つかりました。",
    "ongoing": "報告あり",
    "follow": "フォロー中",
    "doctorChecked": "Dr. 確認済み",
    "edit": "編集",
    "name": "患者様氏名",
    "alternativeName": "患者様氏名（かな）",
    "dateOfBirth": "生年月日",
    "age": "歳",
    "phoneNumber": "電話番号",
    "nextAppointmentType": "次回予約",
    "prescriptionStock": "処方ストック",
    "patientInformation": "患者様情報",
    "activity": "履歴",
    "status": "ステータス",
    "loggedIn": "ログイン済み",
    "notLoggedIn": "未ログイン",
    "patientName": "患者様氏名（漢字）",
    "patientPhoneNumber": "電話番号",
    "patientConditions": "患者様タイプ",
    "conciergeNote": "CSノート",
    "confirmSendEmailToPatient": "送信しますか？",
    "deletePatientConfirmation": {
      "warningLine1": "この操作は元に戻せません！",
      "warningLine2": "患者情報（患者詳細、カルテ、血液検査結果、配送履歴、メディアライブラリー、頭皮アルバム）がすべて削除されます！",
      "warningLine3": "FORESTから{{patientFullName}}を削除しますか？",
      "warningLine4": "実行するには、テキストフィールドにDELETEと入力してください。"
    },
    "mailOptions": {
      "header": "テンプレートを選択してください",
      "infoBloodTest": "検査結果のご連絡",
      "reserveFirstAppointment": "ご予約に関するご案内",
      "shippingCodeInformation": "問い合わせ番号のお知らせ",
      "informTheTrackingNumber": "追跡番号のお知らせ",
      "blank": "ご案内"
    }
  },
  "patientStatus": {
    "Verified": "アクティブ",
    "Test": "テストユーザー",
    "BlockedToScheduleAppointments": "アプリで予約ブロック中",
    "Inactive": "非アクティブ"
  },
  "patientIdentification": {
    "Done": "あり",
    "Required": "次回確認",
    "Unidentified": "なし"
  },
  "EmployeeType": {
    "Operator": "コンシェルジュ",
    "Doctor": "医師",
    "Counselor": "カウンセラー",
    "ClinicManager": "責任者",
    "StaffMember": "医療班",
    "Viewer": "閲覧専用",
    "Admin": "管理者"
  },
  "employeeSeniority": {
    "": "すべて",
    "Senior": "シニア",
    "Intermediate": "ミドル",
    "Junior": "ジュニア"
  },
  "employeeStatus": {
    "": "すべて",
    "Active": "アクティブ",
    "Inactive": "非アクティブ"
  },
  "calendar": {
    "today": "今日",
    "nonWorkingDay": "休診日です",
    "nonWorkingDayDetails": "休診日にスケジュール作成を行うことはできません",
    "noEmployeeSchedule": "シフトが登録されていません",
    "noEmployeeScheduleDetails": "シフトがまだ登録されていません。（予約作成は可能です。予約を作成するとデフォルトシフト上に予約が登録されます。）",
    "noScheduleForLoggedInEmployee": "選択された日のスケジュールがありません",
    "conflictingTimeDividersMessage": "既存の予約と競合するため、一部のタイム ディバイダーは作成されませんでした。 カレンダーを更新してください。",
    "printCalendar": {
      "print": "印刷",
      "togglePhoneNumber": "電話番号表示を切り替え",
      "startTime": "開始時間",
      "bloodExamination": "血液検査結果一覧",
      "appointmentType": "- 診察方法を選択してください -",
      "location": "場所",
      "patientNameAndAlternativeName": "患者様氏名",
      "patientDateOfBirthAndAge": "生年月日（年齢）",
      "patientPhone": "電話番号",
      "roomName": "予定部屋",
      "counselorName": "予定CS",
      "conciergeNotes": "申し送り"
    }
  },
  "toolTip": {
    "refreshPage": "更新",
    "print": "印刷",
    "manageEmployees": "アプリB",
    "timeDivider": "時間枠を作成",
    "timeBlock": "時間をブロック",
    "swap": "変更",
    "edit": "編集",
    "bloodExamination": "血液検査結果一覧",
    "patientDetails": "患者様情報",
    "medicalRecord": "カルテ",
    "createAppointment": "予約作成",
    "editAppointment": "編集する",
    "delete": "削除",
    "mediaLibrary": "メディアライブラリ",
    "callPatient": "テレビ電話",
    "interviewSheet": "問診票",
    "ordersAndPrescriptionsList": "配送依頼と処方リスト",
    "appointmentImages": "写真",
    "copy": "コピー",
    "createRoomEvent": "その他の予定を作成する",
    "viewDetails": "詳細"
  },
  "navBar": {
    "bookingCalendar": "予約カレンダー",
    "roomSchedules": "部屋カレンダー",
    "shipment": "発送リスト",
    "unpaidOrders": "確認待ちのお薬/お支払い",
    "shippedMedicines": "お薬消費リスト",
    "workShift": "シフト表",
    "bloodAppointments": "本日の採血予定リスト",
    "patientRegistration": "新規申込(DB)",
    "addNewPatient": "新規の患者様登録",
    "logOut": "ログアウト",
    "clinicSwitcher": {
      "selectBranch": "院を選択",
      "defaultBranch": "デフォルト院を選択",
      "salon": "サロン"
    },
    "managerMenu": {
      "counselors": "カウンセラー",
      "concierges": "コンシェルジュ",
      "clinicManagers": "クリニックマネージャー",
      "doctors": "医師",
      "medicalTeam": "医療班",
      "viewers": "閲覧専用",
      "medicines": "薬",
      "nonWorkingDays": "休診日"
    }
  },
  "addAutomaticTimeDividersDialog": {
    "title": "時間枠の作成",
    "durationTime": "枠の時間(分)"
  },
  "removeAutomaticTimeDividersDialog": {
    "title": "時間枠の削除"
  },
  "blockTimeDialog": {
    "editBlockTime": "ブロックの編集",
    "addBlockTime": "ブロックの追加",
    "blockTimeColor": "ブロックの色",
    "changeBlockTimeTitle": "ブロック名の編集",
    "deleteBlockTime": "ブロック枠を削除しますか？"
  },
  "extendWorkingShiftDialog": {
    "extendWorkingTime": "勤務時間の延長",
    "workingScheduleEndTime": "勤務の就労時間",
    "errorMessage": "終了時間は現在の勤務時間より後に設定する必要があります",
    "total": "合計",
    "maxHoursPerDay": "最大 20 時間まで"
  },
  "shortenWorkingShiftDialog": {
    "shortenWorkingTime": "勤務時間の縮小",
    "workingScheduleEndTime": "勤務の就労時間",
    "errorMessage": "終了時間は現在の勤務時間より前に設定する必要があります",
    "total": "合計",
    "minMinutesPerDay": "最小 30 分より"
  },
  "endWorkingShiftDialog": {
    "endWorkingTime": "勤務時間の終了",
    "workingScheduleEndTime": "勤務の就労時間",
    "total": "合計",
    "minMinutesPerDay": "最小 30 分より"
  },
  "editMainInformationDialog": {
    "title": "患者情報の編集",
    "refusedToGiveEmail": "アドレス登録拒否"
  },
  "editAddressesDialog": {
    "title": "住所の編集",
    "deliverAtHome": "自宅配送",
    "deliverAtPostOffice": "郵便局留め配送",
    "homeAddress": "住所(自宅）",
    "postCode": "郵便番号 （ハイフンを入れずにご入力ください）",
    "location": "住所",
    "streetName": "住所　番地以降",
    "postOfficeAddress": "住所(郵便局留)",
    "postOfficeName": "郵便局名",
    "prefectureLocation": "郵便局　市区町村",
    "addHomeAddress": "自宅住所の追加",
    "addPostAddress": "郵便局留め住所の追加"
  },
  "editPhoneNumber": {
    "title": "電話番号の編集"
  },
  "editPatientConditionsDialog": {
    "title": "パーソナル"
  },
  "appointment": {
    "appointment": "予約作成",
    "location": "場所",
    "clinic": "来院",
    "appointmentType": "- 診察方法を選択してください -",
    "timeSlot": "- 時間を選択してください -",
    "counselorDuration": "カウンセラー診察時間",
    "doctorDuration": "医師診察時間",
    "doctorStartTimeOffset": "Dr.開始時間",
    "nextAppointmentDetails": "次回予約詳細",
    "availableDoctors": "選択可能医師",
    "availableRooms": "部屋NO",
    "lastFinishedAppointmentType": "前回の予約",
    "lastFinishedAppointmentCounselor": "前回の担当",
    "editAppointment": "変更",
    "appointmentDetails": "予約詳細",
    "conciergeNoteForCommunicationLog": "コンシェルジュへの申し送り（接触ログに記載）",
    "minimumCounselorSeniority": "次回カウンセラーレベル"
  },
  "appointmentLocation": {
    "all": "両方可",
    "Online": "オンライン",
    "Clinic": "来院",
    "Salon": "サロン"
  },
  "appointmentType": {
    "FirstVisit": "初診",
    "Consultation": "相談",
    "ShortConsultation": "相談 [短]",
    "Examination": "診察",
    "ExtendedExamination": "診察60",
    "TreatmentReview": "総括"
  },
  "shortAppointmentType": {
    "FirstVisit": "初",
    "Consultation": "相",
    "ShortConsultation": "相",
    "Examination": "再",
    "ExtendedExamination": "再",
    "TreatmentReview": "総"
  },
  "status": {
    "Unconfirmed": "未確認",
    "PendingPayment": "支払い待ち",
    "PendingBankPayment": "銀行振込待ち",
    "Paid": "支払い済み",
    "Free": "確定待ち",
    "FreeConfirmed": "確定済み（無料）",
    "Shipped": "お渡し済み",
    "Canceled": "キャンセルされました",
    "Expired": "期限切れ"
  },
  "appointmentOngoingActivity": {
    "null": "元に戻す",
    "NoActivity": "元に戻す",
    "WaitingCounselor": "対応待ち",
    "WaitingForCounselor": "対応待ち",
    "Counseling": "対応中",
    "CounselingInProgress": "対応中",
    "WaitingPayment": "会計待ち",
    "WaitingForPayment": "会計待ち",
    "BloodExaminationInProgress": "採血中",
    "Finished": "予約終了"
  },
  "roomEventOngoingActivity": {
    "NoActivity": "元に戻す",
    "Waiting": "対応待ち",
    "Ongoing": "使用中",
    "Finished": "予約終了"
  },
  "appointmentCancellationReason": {
    "PatientRequest": "連絡ありキャンセル",
    "NoAppearance": "連絡なしキャンセル"
  },
  "appointmentCancellationDialog": {
    "descriptionTitle": "予約をキャンセルしますか？",
    "description": "その場合は連絡有無を選択の上、キャンセルを完了してください。",
    "additionalDescription": "こちらを実行すると患者様にキャンセルを通知するSMSが送信されます。"
  },
  "appointmentStatus": {
    "Canceled": "キャンセル済み",
    "Finished": "終了",
    "Scheduled": "予約中"
  },
  "workShift": {
    "manageShiftSchedule": "シフトスケジュールの編集",
    "shiftManagement": "シフトの編集",
    "viewSchedule": "スケジュールの確認",
    "uploadShiftButton": "インポートファイル",
    "fileUploadText": "ファイルアップロード日 :",
    "noShift": "未記入",
    "assignWorkShiftToPeriod": "シフトの設定",
    "assignWorkShiftToPeriodInfo": "シフトは自動的に一週間よりも長い期間で設定されます",
    "selectWorkShift": "シフトを選択してください",
    "selectEmployee": "従業員を選択してください",
    "fromDate": "開始日",
    "toDate": "終了日",
    "assignWorkShifts": "シフトの設定"
  },
  "confirmationDialog": {
    "unsavedChangesText": "保存されていない変更内容があります。変更を破棄しますが宜しいですか？",
    "deleteAddress": "この住所を削除しますか？"
  },
  "dailyNotesDialog": {
    "placeholder": "新規ノートを記入してください",
    "title": "日時ノート",
    "deleteDailyNote": "日次ノートを削除しますか？"
  },
  "createEditAppointment": {
    "newTitle": "新規予約",
    "editTitle": "予約詳細",
    "cancelWarningMessage": "保存されていない変更内容があります。変更を破棄しますが宜しいですか？",
    "createAppointmentMessage": "予約を登録してよろしいですか？",
    "doctorModalInfoText": "カウンセラーを一人選択してください（その後選択可能な医師が表示されます）",
    "noDoctorsAvailable": "この日に対応可能なドクターはいません",
    "doNotSendSmsAndEmailNotificationToPatient": "今から作成する予約には、SMSとemailを送信しません。よろしいですか？"
  },
  "prescription": {
    "prescriptionUpdateNotificationMessage": "カルテにおいて処方が更新されます"
  },
  "date": {
    "startTime": "開始時間",
    "endTime": "終了時間",
    "year": "年",
    "month": "月",
    "day": "日",
    "minute": "分"
  },
  "form": {
    "name": "氏名",
    "surname": "姓（漢字）",
    "alternativeName": "患者様氏名（かな）",
    "alternativeSurname": "姓（ひらがな）",
    "patientName": "名（漢字）",
    "patientSurname": "姓（漢字）",
    "patientAlternativeName": "名（かな）",
    "patientAlternativeSurname": "姓（かな）",
    "device": "携帯電話の種類",
    "birthday": "生年月日",
    "gender": "性別",
    "phoneNumber": "電話番号",
    "orderNo": "順序設定用氏名 (A-Z)",
    "email": "メールアドレス",
    "clinic": "来院",
    "medicineCategory": "薬カテゴリー",
    "date": "日付",
    "type": "タイプ",
    "filter": "絞り込む",
    "search": "検索",
    "status": "ステータス",
    "slackId": "Slack ID",
    "roomNumber": "部屋NO",
    "roomName": "予定部屋",
    "location": "場所",
    "password": "パスワード",
    "repeatPassword": "パスワード入力",
    "assignee": "担当者を入力",
    "selectOption": "--選択してください--",
    "medicineName": "お薬名",
    "secondName": "名前（表示順管理用）",
    "amount": "在庫数",
    "price": "金額",
    "all": "すべて",
    "none": "なし",
    "active": "アクティブ",
    "inactive": "非アクティブ",
    "onlinePrice": "オンライン価格",
    "termsOfService": "同意書",
    "isPrescribable": "処方",
    "available": "利用可能",
    "unavailable": "利用不可",
    "fromDate": "開始日",
    "toDate": "終了日",
    "hasChangedMessage": "他のページで編集されたものがあります。他のページで編集されたものを反映しますか？\nOKを押すと新しいタブが開き、そちらに他のページで編集されたものが反映されます。ご自分で編集していた内容は自動的には反映されませんので、コピペで貼り直してください。",
    "patientSearchPlaceHolder": "患者を検索",
    "medicineSearchPlaceHolder": "薬を検索してください",
    "yen": "円",
    "purchasableInSubscriptionPlan": "定期便で購入可能"
  },
  "manageTimeDividers": {
    "question": "時間枠の作成手法を選んでください",
    "automatically": "自動",
    "manually": "手動"
  },
  "adjustWorkShift": {
    "tooltip": "勤務時間の変更",
    "extend": "拡張する",
    "shorten": "短くする",
    "end": "終わり"
  },
  "genericButtons": {
    "add": "追加",
    "done": "完了",
    "save": "保存",
    "saveChanges": "内容を保存",
    "saveAll": "すべて保存",
    "yes": "あり",
    "yes2": "はい",
    "no": "なし",
    "no2": "いいえ",
    "ok": "OK",
    "confirm": "完了する",
    "start": "開始",
    "finish": "完了",
    "selectAll": "全選択",
    "deselectAll": "一括解除",
    "cancel": "キャンセル",
    "discard": "破棄",
    "discard2": "破棄する",
    "close": "閉じる",
    "print": "印刷",
    "refund": "返金",
    "back": "戻る",
    "next": "続ける",
    "remove": "削除",
    "applyAndSave": "変更内容を保存",
    "clearAll": "選択解除",
    "showList": "処方薬リスト表示",
    "hideList": "処方薬リスト非表示",
    "viewMore": "更に表示する",
    "loadMore": "更に表示する",
    "viewLess": "小さく表示する",
    "edit": "編集",
    "scrollToTop": "ページTOP",
    "clearFilter": "条件をクリア",
    "filter": "絞り込む",
    "confirmCash": "現金",
    "confirmCardPayment": "カード",
    "confirmElectronicMoney": "電子マネー",
    "combinedPayment": "複合支払い",
    "confirmFreeDelivery": "お薬お渡し完了(無料)",
    "confirmBankPayment": "銀行振込確認済み",
    "expired": "期限切れ",
    "complete": "完了",
    "activate": "アクティブにする",
    "deactivate": "非アクティブにする",
    "proceed": "完了する",
    "printAllShipment": "全項目をリスト表示する",
    "printSelectedShipment": "選択された項目をリスト表示する",
    "printAllLabels": "全項目をラベル印刷",
    "printSelectedLabels": "選択された項目をラベル印刷",
    "printAllOrders": "すべての処方箋を印刷する",
    "printSelectedOrders": "選択した処方箋を一括印刷する",
    "exportAsCsv": "クロネコヤマト用 CSV 出力",
    "exportAllSelectedAsCsv": "選択した項目をCSV出力",
    "shipmentComplete": "発送完了",
    "mailTrackingNumber": "メール送信",
    "create": "作成",
    "delete": "削除",
    "callDoctor": "医師呼び出し",
    "register": "登録"
  },
  "genericText": {
    "canceled": "キャンセル済み",
    "changed": "変更済み",
    "comment": "コメント",
    "confirmation": "変更を保存せずに閉じてもよろしいでしょうか？",
    "unsavedChangesDialogMessage": "未保存の変更があります。続行しますか？",
    "showAll": "全表示",
    "emailSent": "送信完了"
  },
  "snackbar": {
    "errorTitle": "エラー"
  },
  "logIn": {
    "title": "メールアドレスを使用してログイン",
    "logIn": "ログイン",
    "forgotPassword": "パスワードを忘れましたか？"
  },
  "forgotPassword": {
    "title": "メールアドレスを入力してパスワードの再設定",
    "send": "送信"
  },
  "resetPassword": {
    "title": "8 文字以上のパスワードを入力してください",
    "setPassword": "パスワード設定"
  },
  "admin": {
    "notifications": {
      "types": {
        "BULK": "通知一括送信",
        "BULK_APPOINTMENT": "予約通知一括送信",
        "BULK_ANNOUNCEMENT": "お知らせ通知一括送信",
        "SINGLE_PATIENT": "単体患者への通知送信"
      },
      "newNotification": "新規通知",
      "notifications": "通知",
      "notificationType": "通知タイプ",
      "sendNotificationAt": "通知送信日",
      "fromDate": "開始日",
      "toDate": "終了日",
      "patientSearch": "検索",
      "patientSearchPlaceHolder": "患者を検索",
      "phoneNumber": "電話番号",
      "url": "URL",
      "title": "タイトル",
      "body": "本文",
      "deleteConfirmation": "この通知を削除しますか？",
      "notificationsNotFound": "現在通知はありません"
    },
    "workShift": {
      "workShifts": "勤務時間",
      "name": "氏名",
      "startsAt": "開始時間",
      "endsAt": "終了時間",
      "availableDays": "利用可能日",
      "clinics": "院",
      "salon": "サロン",
      "timeBlockStartsAt": "ブロック枠開始時間",
      "timeBlockDurationInMinutes": "枠の時間(分)",
      "lunchBreakStartsAt": "休憩開始時間",
      "lunchBreakDurationInMinutes": "枠の時間(分)",
      "active": "アクティブ",
      "inactive": "非アクティブ",
      "edit": "編集",
      "createWorkShift": "シフトの作成",
      "workShiftsNotFound": "空の",
      "addLunchBreak": "休憩の追加",
      "removeLunchBreak": "休憩の削除",
      "addTimeBlock": "ブロック枠追加",
      "removeTimeBlock": "ブロック枠削除",
      "AvailableDays": {
        "Weekend": "週末",
        "Weekday": "平日",
        "Any": "全日"
      }
    },
    "rooms": {
      "rooms": "部屋",
      "editRoom": "部屋の編集",
      "createRoom": "部屋の追加",
      "createRoomTooltip": "部屋の追加",
      "allowPatientMadeAppointments": "患者からの予約作成を可能にする",
      "confirmDeactivateRoom": "この部屋を無効にしますか？",
      "roomsNotFound": "当該院に部屋が作成されていません",
      "roomStatus": {
        "Active": "アクティブ",
        "Inactive": "非アクティブ"
      },
      "availableForPatientMadeAppointments": {
        "yes": "あり",
        "no": "なし"
      }
    },
    "coupons": {
      "coupons": "クーポン",
      "code": "クーポンコード",
      "type": "タイプ",
      "value": "値",
      "status": "ステータス",
      "target": "適用範囲",
      "patient": "患者氏名",
      "validUntil": "終了日",
      "orderType": "タイプ",
      "trackUsage": "利用状況を追跡する",
      "edit": "編集",
      "medicine": "薬",
      "createCoupon": "クーポンの作成",
      "maxUses": "使用可能回数",
      "couponsNotFound": "クーポンがありません",
      "couponCodePatternError": "英語大文字・数字・”＿”記号のみが使用できます",
      "couponType": {
        "FixedCost": "固定金額割引",
        "PercentageCost": "%金額割引",
        "MedicineCost": "品別全額割引",
        "FreeShipping": "送料無料"
      },
      "couponTarget": {
        "SINGLE_PATIENT": "個人の患者",
        "ALL_PATIENTS": "すべての患者",
        "MULTIPLE_PATIENTS": "複数の患者"
      },
      "couponUsage": {
        "OVERALL": "先着〇回まで",
        "PER_PATIENT": "一人それぞれ〇回まで",
        "ONCE_PER_PATIENT": "先着〇名まで(一人一回)"
      }
    },
    "incomeStats": {
      "incomeStats": "総計売り上げ",
      "download": "ダウンロード",
      "type": {
        "TotalIncomePerDay": "日別売り上げ",
        "TotalIncomePerPatient": "患者別売り上げ"
      }
    },
    "clinics": {
      "clinics": "院",
      "name": "院",
      "createClinic": "院/サロンを作成する",
      "editClinic": "院/サロンを編集する",
      "clinicType": "タイプ",
      "webLandingPageAvailability": "予約フォームに表示",
      "mobileAvailability": "アプリに表示",
      "activate": "アクティブにする",
      "deactivate": "非アクティブにする",
      "opensAt": "開院時間",
      "closesAt": "閉院時間",
      "primaryColorHex": "ベースカラー #XXXXXX",
      "address": "住所",
      "phoneNumber": "電話番号",
      "website": "Web URL",
      "latitude": "緯度",
      "longitude": "経度",
      "supervisorDoctor": "監修医師",
      "doctorSearchPlaceholder": "医師を検索する",
      "directorName": "監督名",
      "orderNo": "注文o",
      "generalChannelId": "チャンネル ID",
      "uploadClinicImage": "写真をアップロードする",
      "createClinicTooltip": "院/サロンを作成する",
      "types": {
        "Regular": "一般",
        "Salon": "サロン"
      },
      "webLandingPageAvailabilityConfirmationMessage": {
        "activate": "予約フォーム上に {{clinicName}} を表示します。宜しいですか？",
        "deactivate": "予約フォームから {{clinicName}} を削除・非表示にします。宜しいですか？"
      },
      "mobileAvailabilityConfirmationMessage": {
        "activate": "アプリ上で {{clinicName}} を表示します。宜しいですか？",
        "deactivate": "アプリ上から {{clinicName}} を削除・非表示にします。宜しいですか？"
      }
    }
  },
  "manager": {
    "confirmDeactivateUser": "このアカウントを無効化しますか？",
    "confirmRemoveNonWorkingDay": "休診日を削除してよろしいですか？",
    "searchFilters": {
      "enterSearchParameters": "検索してください",
      "status": "ステータス",
      "type": "タイプ"
    },
    "table": {
      "name": "氏名（漢字）",
      "secondName": "名前（表示順管理用）",
      "medicineName": "お薬名",
      "email": "メール",
      "clinic": "来院",
      "slackId": "Slack ID",
      "status": "ステータス",
      "type": "タイプ",
      "amount": "在庫数",
      "price": "金額",
      "onlinePrice": "オンライン価格",
      "date": "日付",
      "location": "場所",
      "available": "利用可能",
      "availableForPatientMadeAppointments": "患者からの予約作成可能",
      "edit": "編集"
    }
  },
  "counselor": {
    "addCounselor": "新規カウンセラーを作成",
    "editCounselor": "カウンセラー情報",
    "type": "タイプ",
    "applyConciergeAccess": "コンシェルジュ権限を付与する"
  },
  "counselorList": {
    "header": {
      "title": "カウンセラー：",
      "createCounselorTooltip": "新規カウンセラーを作成"
    },
    "counselorsNotFound": "カウンセラーが作成されていません"
  },
  "doctor": {
    "addDoctor": "医師追加",
    "editDoctor": "医師情報",
    "uploadSignature": "サインをアップロード",
    "type": "医師タイプ"
  },
  "doctorsList": {
    "header": {
      "title": "医師",
      "createDoctorTooltip": "新規医師を作成"
    },
    "doctorsNotFound": "医師が作成されていません"
  },
  "concierge": {
    "addConcierge": "新規コンシェルジュを作成",
    "editConcierge": "コンシェルジュ情報",
    "applyCounselorAccess": "カウンセラー権限を付与する"
  },
  "conciergesList": {
    "header": {
      "title": "コンシェルジュ",
      "createConciergeTooltip": "新規コンシェルジュを作成"
    },
    "conciergesNotFound": "コンシェルジュが作成されていません"
  },
  "clinicManager": {
    "addClinicManager": "クリニックマネージャーの追加",
    "editClinicManager": "クリニックマネージャーの編集"
  },
  "clinicManagersList": {
    "header": {
      "title": "クリニックマネージャー",
      "createClinicManagerTooltip": "クリニックマネージャーの追加"
    },
    "clinicManagersNotFound": "該当する院にクリニックマネージャーが存在しません"
  },
  "medicalTeam": {
    "addMedicalTeam": "新規メンバーを作成する",
    "editMedicalTeam": "医療班者情報"
  },
  "medicalTeamList": {
    "header": {
      "title": "医療班",
      "createMedicalTeamTooltip": "新規メンバーを作成する"
    },
    "medicalTeamNotFound": "スタッフメンバーが作成されていません"
  },
  "viewer": {
    "addViewer": "新規メンバーを作成する",
    "editViewer": "閲覧者アカウント情報"
  },
  "viewersList": {
    "header": {
      "title": "閲覧専用",
      "createViewerTooltip": "新規メンバーを作成する"
    },
    "viewerNotFound": "閲覧者が作成されていません"
  },
  "medicinesList": {
    "header": {
      "title": "薬",
      "createMedicineTooltip": "薬の作成"
    },
    "isAvailable": {
      "yes": "あり",
      "no": "なし"
    },
    "addMedicine": "薬の追加",
    "editMedicine": "お薬編集",
    "medicinesNotFound": "お薬がありません",
    "quantityLimited": "CS 無しお渡し個数制限に該当",
    "availableForPatientMadeOrdersWithoutConsultation": "CS無し配送で常時購入可能",
    "purchasableInSubscriptionPlan": "定期便で購入可能"
  },
  "nonWorkingDay": {
    "addNonWorkingDay": "休診日の追加",
    "editNonWorkingDay": "休診日の編集"
  },
  "nonWorkingDaysList": {
    "header": {
      "title": "休診日",
      "createNonWorkingDayTooltip": "休診日を作成"
    },
    "nonWorkingDayNotFound": "休診日が設定されていません"
  },
  "createPatient": {
    "title": "新規の患者登録"
  },
  "patientInformation": {
    "noteInputPlaceholder": "新規ノートを記入してください",
    "forPatient": "患者さま情報",
    "forConcierge": "for コンシェルジュ",
    "forCounsellor": "for カウンセラー",
    "mainInformation": "患者情報",
    "addresses": "住所",
    "automaticNotifications": "通知設定",
    "notifyPatient": "SMS/Email/Push通知でお知らせする",
    "doNotNotifyPatient": "全ての通知をOFFにする (SMS/メール/プッシュ通知)",
    "on": "オン",
    "off": "オフ",
    "patientStatus": "ステータス",
    "identification": "本人確認",
    "homeDelivery": "自宅配送",
    "postOfficeDelivery": "郵便局留め配送",
    "reservationLogs": "予約履歴",
    "reservations": "予約",
    "interviewSheet": "問診票",
    "ordersAndPrescriptionsList": {
      "title": "配送依頼と処方リスト",
      "orders": "今回分のお薬の確認",
      "prescriptions": "処方内容",
      "status": "ステータス",
      "pending": "支払い待ち",
      "medicines": "薬品",
      "totalPrice": "合計",
      "showMedicines": "お薬表示",
      "hideMedicines": "お薬非表示",
      "createdByPatient": "CSなし配送",
      "table": {
        "medicineName": "薬",
        "amount": "数量",
        "price": "金額",
        "refunded": "返金済み"
      },
      "comment": "コメント",
      "totalDiscount": "ディスカウント",
      "cancellationConfirm": "処方・今回分のお薬をキャンセルします。よろしいですか？",
      "refundConfirm": "こちらを返金しますか？",
      "orderListEmpty": "配送依頼がありません",
      "prescriptionListEmpty": "処方がありません",
      "refundCreatedOn": "返金日",
      "customRefundAmount": "合計返金金額:",
      "totalRefundAmount": "返金可能金額:"
    },
    "consentForm": "同意書",
    "contact": {
      "title": "接触",
      "preview": "プレビュー",
      "contactSelectedFor": "選択された連絡先",
      "enterContact": "接触を入力する",
      "archive": "アーカイブ",
      "restore": "元に戻す",
      "archivedLogs": "アーカイブされたログ",
      "contactLog": "接触ログ",
      "emptyContacts": "まだ一度も接触がありません",
      "type": {
        "Inquiry": "お問い合わせ",
        "Appointment": "予約作成",
        "TroubleAboutService": "トラブル",
        "TroubleAboutSystem": "システム不具合",
        "Other": "その他",
        "CancelAppointment": "連絡なし予約キャンセル",
        "DeleteAppointment": "予約削除",
        "EditAppointment": "予約変更",
        "Payment": "お支払い",
        "MistakeFromClinic": "当院ミス"
      },
      "method": {
        "PhoneCall": "電話",
        "InTheClinic": "CS時",
        "Application": "アプリから",
        "Forest": "FORESTから",
        "Others": "その他",
        "ReceivePhoneCall": "着信",
        "MakePhoneCall": "発信",
        "InPerson": "メール送信",
        "WebApp": "FORESTから",
        "AppointmentForm": "即時"
      },
      "contactDialog": {
        "enterContact": "接触を入力する",
        "branch": "院",
        "dateOfOccurrence": "発生日",
        "timeOfOccurrence": "発生時間",
        "contactMethod": "接触方法",
        "comment": "コメント",
        "post": "投稿する",
        "enterTextMessage": "ここにテキストを入力してください"
      }
    },
    "contactTable": {
      "date": "日付",
      "type": "種類",
      "time": "時間",
      "contactMethod": "接触方法",
      "responsible": "担当",
      "comment": "コメント",
      "dateAndTimeOfReservation": "予約の日時",
      "archive": "アーカイブ",
      "restore": "元に戻す",
      "appointmentCancelledByPatientRequest": "連絡あり予約キャンセル"
    },
    "reservationTable": {
      "order": "注文",
      "location": "場所",
      "dateAndTimeOfReservation": "予約の日時",
      "type": "タイプ",
      "counselor": "カウンセラー",
      "status": "ステータス",
      "edit": "編集"
    },
    "medicines": {
      "title": "お薬",
      "medicinesTabFilter": {
        "PrescriptionDrugs": "処方薬品",
        "CompletedOrders": "配送/支払",
        "AllOrders": "お渡し内容",
        "Refund": "返金"
      },
      "quantity": "数量",
      "physician": "処方医師",
      "patientMade": "CSなし配送",
      "edit": "編集",
      "tos": "同意書"
    },
    "order": {
      "preparationDate": "今回分のお薬作成日",
      "status": "ステータス",
      "price": "金額",
      "paymentConfirmationDate": "支払/振込確認日時",
      "shipmentDate": "発送/支払い完了日時",
      "paymentDate": "支払い/確定/振込確認日時",
      "paymentMethod": "支払い方法",
      "location": "発送先",
      "refundDate": "返金完了日時",
      "amount": "返金の対象となる金額",
      "content": "内容",
      "editPaymentMethod": "支払い方法を変更する",
      "totalPrice": "合計",
      "theWayOfPayment": "支払い方法",
      "creditCard": "クレジットカード",
      "cash": "現金",
      "electronicMoney": "電子マネー"
    }
  },
  "ContactUpdateMode": {
    "archive": "アーカイブ",
    "unArchive": "元に戻す"
  },
  "deliveryLocation": {
    "Home": "自宅",
    "PostOffice": "郵便局"
  },
  "paymentMethod": {
    "CARD": "クレジットカード",
    "BANK": "銀行振込",
    "CLINIC": "来院",
    "CASH": "現金",
    "ELECTRONIC": "電子マネー",
    "MIXED": "複合支払い"
  },
  "consentForm": {
    "openConsentForm": "同意書を開く",
    "agree": "同意します",
    "disagree": "同意しません",
    "tosAcceptedMessage": "ご同意ありがとうございます。\n 端末はスタッフにご返却ください。"
  },
  "gender": {
    "Male": "男性",
    "Female": "女性",
    "NotDefined": "その他"
  },
  "deviceType": {
    "Ios": "iOS",
    "Android": "Android",
    "DeviceNotDefined": "その他",
    "NotAsked": "未確認"
  },
  "bloodExamination": {
    "title": "血液検査結果一覧",
    "createNew": "保存",
    "createNewExamination": "新規作成",
    "emptyListMsg": "血液検査情報はありません",
    "contactDate": "接触日",
    "contact": "連絡",
    "patientContact": {
      "contactRequired": "要",
      "contactNotRequired": "不要"
    },
    "contactComment": "接触コメント",
    "contactStatus": "メールステータス",
    "dayOfBloodExamination": "採血日",
    "status": "ステータス",
    "trouble": "採血トラブル",
    "troubleValue": {
      "had_trouble": "異常なし",
      "no_trouble": "異常あり"
    },
    "nameOfNurse": "採血者",
    "dayTheResultWasChecked": "採血確認日",
    "doctorCheckedTheResult": "採血確認者",
    "possibleOrNot": "内服可否",
    "nextBloodExaminationPeriod": "次回確認時期(一般)",
    "nextSpecialBloodExaminationPeriod": "次回確認時期(特殊)",
    "nextBloodExaminationTitle": "次回確認時期",
    "specialType": "特殊",
    "regularType": "一般",
    "liver": "肝機能",
    "renal": "腎機能",
    "special": "特殊",
    "female": "女性",
    "female-BIMAN-type": "女性（びまん性）",
    "bloodExaminationComment": "フリーテキスト欄",
    "bloodExaminationNurseComment": "採血者申し送り欄",
    "confirmDeleteDialogMessage": "こちらの血液検査データを削除してよろしいですか？",
    "editButton": "編集",
    "cancelButton": "キャンセル",
    "submitButton": "保存",
    "updateButton": "保存",
    "toastMessages": {
      "newBloodExaminationCreated": "新規の血液検査情報を作成しました",
      "newBloodExaminationNotCreated": "新しい血液検査情報は作成されませんでした",
      "bloodExaminationHasBeenUpdated": "血液検査情報は更新されました",
      "bloodExaminationHasNotBeenUpdated": "血液検査情報は更新されませんでした",
      "bloodExaminationDeleted": "血液検査データは削除されました"
    },
    "height": "身長",
    "weight": "体重",
    "deleteBloodExamination": "採血を削除しますか？"
  },
  "bloodExaminationStatus": {
    "WaitingForResults": "結果待ち",
    "Optional": "任意",
    "Skip": "スキップ",
    "Error": "エラー／不備あり",
    "HavingResults": "次回診察",
    "ResultsExplained": "結果説明済み"
  },
  "bloodExaminationTrouble": {
    "noTrouble": "異常なし",
    "hadTrouble": "異常あり"
  },
  "bloodExaminationPossible": {
    "possibleNoLetterOfReference": "内服開始可/紹介状なし",
    "possibleHaveLetterOfReference": "内服開始可/紹介状あり",
    "notPossibleLetterOfReference": "内服不可/紹介状なし",
    "notPossibleHaveLetterOfReference": "内服不可/紹介状あり"
  },
  "nextBloodExaminationPeriod": {
    "InOneYear": "1年後",
    "InHalfAYear": "半年後",
    "InThreeMonths": "3ヶ月後",
    "letterOfReference": "紹介状",
    "letterOfReferenceNoImprovementsInNextAppointment": "次回改善なければ紹介状"
  },
  "sideEffect": {
    "ongoing": "報告あり",
    "viewDetails": "詳細"
  },
  "sideEffectOptions": {
    "Follow": "フォロー中",
    "DoNotFollow": "フォロー不要"
  },
  "medicalRecord": {
    "appointmentList": {
      "compare": "比較"
    },
    "appointmentRecordHasBeenUpdated": "カルテが更新されました",
    "callDoctorInfo": "メッセージを送信しました",
    "earlyAppointmentCallConfirmation": "予約時間にまだなっていません。ビデオ電話を開始しますか？",
    "changeRoomStatusToOngoing": "発信前に、部屋ステータスを[対応中]に変更してください",
    "examination": "受診",
    "prescriptionAndDelivery": "配送依頼と処方リスト",
    "editQuestions": "カルテを編集する",
    "saveQuestions": "変更を保存",
    "copyGeneralSectionFromPreviousAppointmentButton": "前回のサマリーを反映する",
    "copyGeneralSectionFromPreviousAppointmentConfirmation": "前回の内容を反映すると、現在記入されている内容は上書きにより消えてしまいます。本当に反映してよろしいですか？",
    "finishAppointmentConfirmationMessage": "完了しますか？（この操作は戻せません）",
    "viewDetails": "詳細",
    "viewPatientStock": "処方残を表示する",
    "heartStats": {
      "top": "上",
      "bottom": "下",
      "pressure": "血圧",
      "pulse": "脈拍"
    },
    "generalSection": {
      "area": "お住まい",
      "areaOptions": {
        "Hokkaido": "北海道",
        "Aimori": "青森県",
        "Iwate": "岩手県",
        "Miyagi": "宮城県",
        "Akita": "秋田県",
        "Yamagata": "山形県",
        "Fukushima": "福島県",
        "Ibaraki": "茨城県",
        "Tochigi": "栃木県",
        "Gunma": "群馬県",
        "Saitama": "埼玉県",
        "Tokyo": "東京都",
        "ChibaKen": "千葉県",
        "Kanagawa": "神奈川県",
        "Niigata": "新潟県",
        "Toyama": "富山県",
        "Ishikawa": "石川県",
        "Fukui": "福井県",
        "Yamanashi": "山梨県",
        "Nagano": "長野県",
        "Gifu": "岐阜県",
        "Shizuoka": "静岡県",
        "Aichi": "愛知県",
        "Mie": "三重県",
        "Shiga": "滋賀県",
        "Kyoto": "京都府",
        "Osaka": "大阪府",
        "Hyogo": "兵庫県",
        "Nara": "奈良県",
        "Wakayama": "和歌山県",
        "Tottori": "鳥取県",
        "Shimane": "島根県",
        "Okayama": "岡山県",
        "Hiroshima": "広島県",
        "Yamaguchi": "山口県",
        "Tokushima": "徳島県",
        "Kagawa": "香川県",
        "Ehime": "愛媛県",
        "Kouchi": "高知県",
        "Fukuoka": "福岡県",
        "Saga": "佐賀県",
        "Nagasaki": "長崎県",
        "Kumamoto": "熊本県",
        "Oita": "大分県",
        "Miyazaki": "宮崎県",
        "Kagoshima": "鹿児島県",
        "Okinawa": "沖縄県",
        "Overseas": "海外・その他",
        "undefined": ""
      },
      "diagnosis": "診断",
      "pastDiagnosis": "参考診断",
      "diagnosisOptions": {
        "type_II": "Ⅱ型",
        "type_IIv-I": "Ⅱv型（生え際Ⅰ型）",
        "type_IIv-II": "Ⅱv型（生え際Ⅱ型）",
        "type_IIv-III": "Ⅱv型（生え際Ⅲ型）",
        "type_IIa": "Ⅱa型",
        "type_III": "Ⅲ型",
        "type_IIIv-I": "Ⅲv型（生え際Ⅰ型）",
        "type_IIIv-II": "Ⅲv型（生え際Ⅱ型）",
        "type_IIIa": "Ⅲa型",
        "type_IV": "Ⅳ型",
        "type_IVa": "Ⅳa型",
        "type_V": "Ⅴ型",
        "type_Va": "Ⅴa型",
        "type_VI": "Ⅵ型",
        "type_VII": "Ⅶ型",
        "type_ludwigI": "ルードヴィヒⅠ型",
        "type_ludwigII": "ルードヴィヒⅡ型",
        "type_ludwigIII": "ルードヴィヒⅢ型",
        "undefined": "",
        "type_IIv-IIa": "Ⅱv型（生え際Ⅱa型）",
        "type_IIv-IIIa": "Ⅱv型（生え際Ⅲa型）",
        "type_IIIv-IIa": "Ⅲv型（生え際Ⅱa型）"
      },
      "treatment": "方針",
      "treatmentOpen": "開く",
      "concerningMatters": "気になる部位",
      "concerningMattersOptions": {
        "top": "頭頂部",
        "edge": "生え際",
        "whole": "全体",
        "front": "前頭部",
        "partition": "分け目",
        "prevention": "予防",
        "fallingDownHair": "抜け毛",
        "volume": "ボリューム"
      },
      "importantNote": "注意事項記入欄",
      "copyFromPreviousAppointmentButton": "前回のサマリーを反映する",
      "copyFromPreviousAppointmentConfirmation": "前回の内容を反映すると、現在記入されている内容は上書きにより消えてしまいます。本当に反映してよろしいですか？"
    },
    "notesForGeneralSection": "フリーテキスト記入欄",
    "examinationSection": {
      "consultationType": {
        "consultationTypeQuestion": "選択式",
        "doctorChangeQuestion": "ドクター変更",
        "title": "CS/診察",
        "consultationTypeOptions": {
          "pleaseSelect": "--選択してください--",
          "onlyConsultation": "CSのみ",
          "examinationWithoutPrescription": "診察/処方なし",
          "examinationWithPrescription": "診察/処方",
          "none": "なし"
        }
      },
      "firstVisitHeadSkinSymptomsSection": {
        "dandruff": "ふけ",
        "itching": "かゆみ",
        "other": "その他",
        "title": "頭皮の自覚症状"
      },
      "headSkinSymptomsSection": {
        "title": "自覚症状の変化",
        "healthConditionQuestion": {
          "title": "体調変化",
          "options": {
            "yes": "あり",
            "no": "なし"
          }
        },
        "hairLossQuestion": {
          "title": "抜け毛",
          "options": {
            "increase": "増加 ",
            "same": "不変",
            "decrease": "減少",
            "notSure": "不明",
            "initialHairLossOngoing": "初期脱毛(継続)",
            "initialHairLossFinished": "初期脱毛(終了)"
          }
        },
        "glossAndStiffnessQuestion": {
          "title": "ツヤコシ",
          "options": {
            "increase": "増加",
            "same": "不変",
            "decrease": "減少",
            "notSure": "不明"
          }
        },
        "awarenessOfHairIncreaseQuestion": {
          "title": "増毛の自覚",
          "options": {
            "increaseEdgeLine": "増加(生え際)",
            "increaseTop": "増加(頭頂部)",
            "same": "不変",
            "decrease": "減少",
            "notSure": "不明",
            "downyHair": "産毛"
          }
        },
        "bodyHairQuestion": {
          "title": "体毛",
          "options": {
            "increase": "増加",
            "same": "不変"
          }
        }
      },
      "prescriptionsSection": {
        "title": "処方・お渡し",
        "prescriptionComment": "処方:",
        "deliveryComment": "お渡し:"
      },
      "extendedExaminationSummarySection": {
        "title": "まとめ",
        "previousExtendedExaminationComparisonComment": {
          "title": "写真比較"
        },
        "degreeOfSatisfactionComment": {
          "title": "満足度",
          "overallSatisfactionTitle": "総合評価",
          "effectOfTreatmentTitle": "︎治療効果",
          "satisfactionOfServiceTitle": "︎サービス / その他"
        },
        "patientRequestQuestion": {
          "title": "今後のご希望",
          "options": {
            "keep": "現状維持",
            "increase": "増毛",
            "others": "その他"
          }
        },
        "mgpaScoreQuestion": {
          "title": "MGPA スコア"
        }
      },
      "firstImpressionFromSkinCheckSection": {
        "title": "頭皮所見",
        "exanthema": "発疹",
        "flare": "発赤",
        "pimple": "にきび",
        "dandruff": "ふけ"
      },
      "photoComparisonSection": {
        "title": "写真による比較",
        "overallStiffnessQuestion": {
          "title": "全体のこし・つや"
        },
        "headTopQuestion": {
          "title": "頭頂部"
        },
        "hairOfHairlineQuestion": {
          "title": "生え際の産毛、M字のライン"
        },
        "exanthemaQuestion": {
          "title": "皮疹の有無"
        },
        "options": {
          "increase": "改善",
          "same": "不変",
          "decrease": "増悪"
        }
      },
      "photographicFindingsSection": {
        "title": "写真所見",
        "top": "頭頂部",
        "topOfTheHeadQuestions": {
          "range": "[範囲]",
          "degree": "[程度]"
        },
        "hairLine": "ヘアライン",
        "hairLineQuestions": {
          "goingBack": "[後退]",
          "inDistinct": "[不明瞭化]",
          "degreeOfGoingBack": "[後方の程度]",
          "rangeOfGoingBack": "[後方の範囲]"
        }
      },
      "bloodPressureAndPulseSection": {
        "title": "血圧/脈拍",
        "upperBloodPressure": "上",
        "lowerBloodPressure": "下",
        "pulse": "脈拍",
        "unusualTestResult": "血圧・心電図・レントゲンでの指摘歴",
        "regularChecks": "定期受診の有無",
        "unusualResultsFromPastChecks": "定期受診での指摘歴",
        "yes": "あり",
        "no": "なし"
      },
      "pastExperienceOfHairlossTreatmentSection": {
        "title": "治療歴",
        "yes": "あり",
        "no": "なし"
      },
      "supplementSection": {
        "title": "サプリメント",
        "yes": "あり",
        "no": "なし"
      },
      "newTreatmentPlanSection": {
        "title": "新方針",
        "multipleMonthsQuestion": {
          "title": "複数月お渡し希望"
        },
        "yes": "あり",
        "no": "なし"
      },
      "patientAimSection": {
        "title": "ご希望",
        "increaseAmount": "増毛",
        "keepAmount": "維持予防"
      },
      "medicalHistorySection": {
        "title": "既往歴",
        "yes": "あり",
        "no": "なし",
        "householdMedicine": "常備薬",
        "pastHistoryOfProstaticCancerInFamily": "前立腺癌の家族歴",
        "illHealth": "体調不良",
        "allergyToMedicine": "薬アレルギー",
        "pregnancy": "(女性の場合) 妊娠",
        "pregnancyOptions": {
          "yes": "あり",
          "no": "なし",
          "possible": "可能性あり"
        }
      },
      "nextAppointmentDetailsSection": {
        "nextAppointmentType": "次の予約",
        "finishExamination": "診察終了",
        "makeBloodExaminationOnNextAppointment": "次回の予約で採血を実施する",
        "assignExperiencedCounselorOnNextAppointment": "シニアカウンセラーをアサインする",
        "finishExaminationConfirmationMessage": "この診察を終了しますか？（この操作は元に戻せません）"
      }
    },
    "sideEffectsDialog": {
      "title": "詳細",
      "detailsForDoctor": "報告内容",
      "futureActions": "今後の対応",
      "doctorChecked": "Dr. 確認済み",
      "writeCommentPlaceholder": "コメント"
    },
    "prescription": {
      "title": "処方内容",
      "prescriptionStock": "処方ストック",
      "doctorCommentTitle": "コメント記入欄",
      "confirmCreatingPrescriptionTitle": "処方薬品を保存しますか？",
      "inStock": "在庫",
      "takeMedicinesEverySecondDay": "FINA/DUTA, 隔日投与",
      "deselectAll": "何もありません",
      "doctorCommentPlaceholder": "コメントを入力してください",
      "createPrescriptionButton": "保存",
      "noPrescriptionMessage": "この診察/相談で作られた処方薬品はありません",
      "orderExpiredMessage": "期限切れのためキャンセルになりました",
      "manualCancellationMessage": "手動でキャンセル済みです",
      "previouslyPrescribedMedicines": "前回の処方",
      "cancelPrescription": "処方をキャンセルしますか？"
    },
    "patientStockFromAnotherClinic": {
      "clinicAppointmentMessage": "現在保有しているストックは,、こちらの院ではお渡しできません。リセットして処方から再登録してください。",
      "onlineAppointmentMessage": "※ 別の院で処方されたお薬同士を同時にストックとして持つことはできません",
      "prescriptionStockIn": "現在保持している処方ストック:"
    },
    "order": {
      "title": "今回分のお薬の確認",
      "nonPrescribableMedicine": "処方箋不要",
      "clearMedicineStock": "お薬ストックを消去",
      "confirmCreatingOrderTitle": "今回分のお薬をアプリに登録しますか？<br> ※テレビ電話の場合はすぐに患者さんに通知が飛びます。",
      "confirmPaymentOrderTitle": "この今回分のお薬の支払いと受け渡しを完了しますか？ ※完了後は処方・今回分のお薬の修正はできません。",
      "confirmBankPaymentOrderTitle": "銀行振込の確認を完了しましたか？こちらを完了すると、このお薬情報は発送リストに表示されます。",
      "confirmExpiredOrderTitle": "この支払いを手動で期限切れにしますか？",
      "combinedPaymentDialogTitle": "それぞれの支払い方法の金額を入力してください",
      "amountPaidWithCash": "現金で支払われた金額",
      "amountPaidWithCreditCard": "クレジットカードで支払われた金額",
      "amountPaidWithElectronicMoney": "電子マネーで支払われた金額",
      "amountRequired": "支払いの合計額はです",
      "wayOfPayment": "お支払い方法",
      "cashAmount": "現金",
      "creditCardAmount": "クレジットカード",
      "electronicMoneyAmount": "電子マネー",
      "viewInOrdersList": "配送リストの表示",
      "noOrderMessage": "この診察/相談で作られた今回分のお薬はありません",
      "orderExpiredMessage": "期限切れになりました",
      "manualCancellationMessage": "発送内容をキャンセルしました",
      "refunded": "返金しました",
      "refundCreatedOn": "返金依頼作成日",
      "customRefundAmount": "合計返金金額:",
      "totalRefundAmount": "返金可能金額:",
      "deliveryCost": "送料",
      "cancelOrder": "注文を取り消しますか？",
      "discountSection": {
        "applyDiscountQuestion": "割引を設定しますか？",
        "useOnlinePrices": "オンライン値引きを適用する",
        "promoCode": "プロモーションコード",
        "deduction": "値引き",
        "deductionOnTotalPrice": "お値引き (¥)",
        "deductionExplanation": "プロモーションコードをお持ちの場合は、割引額総計に対しての割引を行います。",
        "applyCode": "変更内容を保存",
        "totalDiscount": "値引",
        "totalPriceWithDiscount": "合計金額",
        "refund": "返金",
        "discount": "ディスカウント",
        "invalidCode": "コードが正しくありません"
      }
    },
    "examinationNoteCancelChangesConfirmationDialog": {
      "unsavedChangesText": "カルテ内容の変更を保存せずに終了しますか？"
    }
  },
  "medicine": {
    "name": "薬",
    "quantity": "数量",
    "remainingQuantity": "残数",
    "price": "金額",
    "discountPrice": "お値引き額e",
    "onlinePrice": "オンライン価格",
    "confirmSubmit": "お薬の設定変更を行います。定期便の設定を変更した場合、既存の患者様の定期便にも影響が出ます。設定を変更してよろしいですか？"
  },
  "mediaLibrary": {
    "mediaLibrary": "メディアライブラリ",
    "title": "タイトル",
    "date": "日付",
    "time": "時間",
    "emptyMediaLibraryMessage": "メディアライブラリは空です",
    "addFile": "追加",
    "edit": "編集",
    "delete": "削除",
    "editTitleMessage": "タイトルを編集しますか？",
    "editingCompleteMessage": "タイトルの編集が完了しました",
    "deleteMediaMessage": "この画像を削除しますか？",
    "deleteSuccessMessage": "削除が完了しました",
    "patientUploadedPhotos": "患者様より、{{value.date}}に{{value.numberOfPhotos}}枚の写真がアップロードされました。",
    "documents": "ドキュメント",
    "letterOfReference": {
      "letterOfReference": "診療情報提供書",
      "createNewLetterOfReference": "新しい紹介状を作成",
      "title": "タイトル",
      "edit": "編集",
      "delete": "削除",
      "emptyLetterOfReferenceList": "紹介状はありません",
      "letterOfReferenceCreated": "紹介状を作成しました。",
      "letterOfReferenceNotCreated": "紹介状を作成しましたませんでした",
      "letterOfReferenceUpdated": "紹介状を更新しました",
      "letterOfReferenceNotUpdated": "紹介状を更新しませんでした",
      "approved": "医師の確認済み",
      "close": "キャンセル",
      "submit": "保存",
      "shouldApproveLetterOfReference": "この紹介状を確認しましたか？ <br> 医師の確認後に、この紹介状は印刷可能になります。",
      "shouldDisapproveLetterOfReference": "この紹介状の確認を取り消しますか？  <br>  取り消すとこの紹介状は印刷できなくなります。",
      "doctorName": "医師氏名",
      "patientName": "患者氏名",
      "institutionName": "紹介先医療機関名",
      "docSignature": "先生御侍史",
      "issuingHospitalAddress": "紹介元医療機関の住所地",
      "issuingHospitalName": "及び名称",
      "phoneNumber": "電話番号",
      "faxNumber": "FAX番号",
      "nameSuffix": "様",
      "purposeOfReferring": "紹介目的",
      "nameOfDisease": "傷病名",
      "treatmentResults": "症状経過・治療経過・検査結果"
    }
  },
  "errors": {
    "genericError": "エラーが発生しました。もう一度お試しください。",
    "newApplicationVersion": "FORESTの新しいバージョンを利用できます。10秒経っても自動的に更新されない場合、更新ボタンからリロードしてください。",
    "schedulingNonWorkingDayWithAppointments": "選択日は予定があるため設定できません",
    "schedulingNonWorkingDayForClinicAlreadyExists": "選択した日は既に休診日に設定されています",
    "schedulingReservationOutsideWorkShift": "予約がシフト可能時間外に設定されています",
    "schedulingOverlappingReservation": "時間枠もしくは既存の予約と重なっているため保存できません",
    "schedulingReservationInPast": "予約を過去にさかのぼって作成することはできません",
    "schedulingArgumentsInvalid": "予約に必要なフィールドが設定されていません",
    "schedulingWorkShiftInactive": "選択された勤務シフトは非アクティブです",
    "schedulingWorkShiftDurationShort": "終了時間を過ぎてシフトを開始しようとしています",
    "schedulingShortenWorkShiftAppointmentStarted": "完了していない予約が存在するためシフトを終了することができません",
    "schedulingWorkShiftAssignedInPresentOrFuture": "先のシフトで使用されているため、非アクティブにできません",
    "invalidFileSelected": "選択したファイル形式はサポートされていません",
    "orderManagementMedicineNotAvailableInStock": "クリニックに存在しないお薬の発送が依頼されています",
    "orderManagementMedicineUsedInOrders": "完了していない配送に薬が含まれているため非アクティブにすることができません\n",
    "notificationPatientMissingDeviceType": "患者はログインしていません",
    "notificationPatientMissingDeviceToken": "患者はログインしていません",
    "notificationPatientExpiredDeviceToken": "患者はログインしていません",
    "notificationInThePast": "通知はすでに配信されています",
    "medicalEmailPatientAlreadyExists": "このアドレスは既に登録されています",
    "required": "必須項目です",
    "minimumLength": "最小の長さは 8 です",
    "invalidEmail": "正しくないメールフォーマットです",
    "userExists": "ユーザーはすでに存在します",
    "invalidDate": "過去の日付を選択しています",
    "orderManagementCouponRestricted": "コードが正しくありません",
    "uniqueCouponCode": "同じコードを持つクーポンが既に存在しています",
    "loginFailed": "ログインに失敗しました",
    "loginInvalid": "入力情報が正しくありません",
    "tokenExpired": "トークンの期限切れ",
    "phoneInvalid": "使用できない文字列が含まれています",
    "medicalAppointmentFinished": "情報が最新でないため、一度リロードして再度お試しください",
    "medicalAppointmentNotStarted": "予約時間にまだなっていません",
    "medicalAppointmentMissingExamination": "カルテより診察を終了してください",
    "medicalAppointmentExaminationFinished": "診察が既に終了している予約をキャンセルすることはできません\n",
    "patientHasUnpaidOrders": "該当の患者様は未払いの発送があります",
    "orderManagementMedicineUsedInMedicineStock": "この薬は現在アクティブな配送依頼・もしくは患者様の処方に存在しています。ステータス変更が必要な場合には、新規に薬を設定のうえ、この薬を利用不可としてください。",
    "medicalRecordPatientAlreadyMarkedForDelete": "この患者は現在削除されています。",
    "medicalPatientNotFound": "この患者は存在しません。",
    "workShift": {
      "WorkShiftStarted": "勤務シフトは既に開始されています",
      "ExistingDailyScheduleFound": "重複するスケジュールがあります。ページを再読み込みしてください。",
      "DailyScheduleNotFound": "該当するスケジュールが見つかりませんでした",
      "DailyScheduleAppointmentsStarted": "既に開始された予約が存在しています。",
      "ClinicNonWorkingDay": "休診日です",
      "WorkShiftTooShort": "シフトが短すぎます。より長いシフトの選択をしてください。",
      "WorkShiftOnWrongDay": "シフトが正しくない日付に設定されています。",
      "WorkShiftNotAvailableOnDay": "設定された日に対してシフトが存在しません。",
      "WorkShiftOutsideWorkingHours": "シフトが勤務可能時間を超えています。",
      "WorkShiftConflictsWithAppointments": "シフトが従業員の予約と重複しています。",
      "LunchBreakConflictsWithAppointments": "昼休憩の設定が既に存在する予約と重複しています。",
      "BreakInThePast": "昼休憩の設定時間が過ぎています"
    }
  },
  "nothingFound": {
    "title": "検索結果はありません。"
  },
  "supervisorDoctor": "監修医師",
  "refund": {
    "title": "返金の手続き",
    "subtitle": "返金するお薬を選択するか、金額を入力してください",
    "selectMedicines": "返金するお薬を選択する",
    "medicineName": "薬",
    "price": "金額",
    "amount": "在庫数",
    "refundAmount": "返金可能金額",
    "totalRefundAmount": "合計返金額",
    "refundCostOfDelivery": "返金 - 送料",
    "enterAmount": "内容 - 金額を入力する",
    "contents": "内容",
    "totalRefundAmountLabel": "合計返金額 (¥)",
    "refundableAmount": "返金可能金額",
    "itemsToBeChecked": "返金手続きを行う上での確認事項",
    "inCaseOfBank": "銀行振り込みの場合",
    "pressRefundButton": "銀行口座への振り込みが完了してから「返金する」ボタンを押してください。",
    "clinicReservation": "クリニック予約の場合",
    "pushRefundButton": "患者様へ直接返金が完了してから「返金する」ボタンを押してください。",
    "onlineReservation": "オンライン予約・クレジットカード払いの場合",
    "completeOperation": "このページでの操作を完了すると、自動的にStripeからクレジットカードへの"
  },
  "roomSchedules": {
    "title": "部屋カレンダー",
    "today": "今日",
    "gridView": "グリッドビュー",
    "calendarView": "部屋カレンダー",
    "clinicRoomEvent": "他",
    "deleteRoomEventConfirmation": "本当にこの予定を削除しますか？",
    "minutes": "分",
    "finished": "終了",
    "createRoomEvent": "その他の予定を作成する",
    "editRoomEvent": "予定を編集する",
    "editRoomAppointment": "予定を編集する",
    "counselorNamePrefix": "カ",
    "writeCommentHere": "コメント",
    "emptyRoom": "空き部屋",
    "unknownRooms": "その他",
    "finishEventConfirmation": "この予定を終了しますか？",
    "refreshPageConfirmation": "他の人による変更があります。この画面の更新してください。"
  },
  "connectionStatus": {
    "Connected": "接続中",
    "Reconnected": "再接続中（ページを更新してください",
    "Disconnected": "切断中"
  },
  "noteForConcierge": {
    "title": "申し送り"
  },
  "callScreen": {
    "callPatient": "テレビ電話",
    "phoneNumber": "電話番号",
    "outgoing": "発信中です",
    "patientId": "ID",
    "sendNotification": "通知を送る",
    "hangUp": "切断",
    "patientUnavailable": "応答しません",
    "callDeclined": "通話を拒否されました",
    "networkConnectionProblems": "インターネット設定に問題があります。再接続を試みています…",
    "genericPatientDisconnect": "切断されました",
    "ongoingCall": "通話中",
    "selectImagesToCompare": "比較する画像を選択してください",
    "today": "今日",
    "top": "頭頂部",
    "right": "M字右",
    "left": "M字左",
    "headskin1": "その他1",
    "headskin2": "その他2",
    "headskin3": "その他3",
    "temporaryStorage": "一時保存",
    "sharedScreen": "共有スクリーン",
    "headPosition": {
      "Top": "頭頂部",
      "Left": "M字左",
      "Right": "M字右",
      "Other": "その他1",
      "Headskin1": "その他2",
      "Headskin2": "その他3"
    },
    "unsavedScreenShotsDialog": {
      "endCall": "画像がまだ保存されていません。保存せずに通話を終了しますか？すべての仮アップロード済みの画像は破棄されます。",
      "cancel": "画像がまだ保存されていません。画像アップロードをキャンセルしますか？すべての仮アップロード済みの画像は破棄されます。"
    },
    "uploadImages": "画像アップロード中",
    "openTokError": {
      "OT_STREAM_DESTROYED": "エラーが発生しました。もう一度お試しください",
      "OT_CHROME_MICROPHONE_ACQUISITION_ERROR": "ブラウザがマイクにアクセスできないようです。ブラウザを再起動し、ページを更新してください。",
      "OT_MEDIA_ERR_NETWORK": "ネットワークエラーです。インターネット環境を確認してください。",
      "OT_NO_DEVICES_FOUND": "このコンピューターにはマイクとカメラがありませんので、テレビ電話を行うことができません。",
      "OT_MEDIA_ERR_ABORTED": "このコンピューターにはマイクとカメラがありませんので、テレビ電話を行うことができません。",
      "OT_NOT_CONNECTED": "ネットワークに接続していないので操作が完了しませんでした。",
      "OT_USER_MEDIA_ACCESS_DENIED": "カメラとマイクのアクセスが許可されていません。カメラとマイクを許可してから、画面を更新してください。",
      "SOMETHING_WENT_WRONG": "不明の問題が起こりテレビ電話を行うことができません。システム管理者に連絡してください。",
      "genericError": "エラーが発生しました。もう一度お試しください",
      "OT_CONNECT_FAILED": "接続に失敗しました",
      "OT_TIMEOUT": "エラーが発生しました。もう一度お試しください",
      "OT_UNEXPECTED_SERVER_RESPONSE": "OpenTokのサーバーで予期せぬエラーが発生しました",
      "OT_HARDWARE_UNAVAILABLE": "カメラもしくはマイクが使用できません",
      "audioPermissionsNotGranted": "この患者様はカメラ及びマイクへの許可を取り消したため、患者様側からの映像・音声が届きません。（医師側からの声・映像は届いています。）",
      "videoPermissionsNotGranted": "この患者様はカメラ及びマイクへの許可を取り消したため、患者様側からの映像・音声が届きません。（医師側からの声・映像は届いています。）",
      "onHold": "患者様が別のお電話に出られたため、テレビ電話を一時停止しております。患者様がお電話を終了後、再び繋がりますのでしばらくお待ちください。"
    },
    "imageBoard": {
      "clickBlockDescription": "ここをタップしてキャプチャ画像を選んでください",
      "selectLeftImageDescription": "左側のキャプチャを選択(上)",
      "selectRightImageDescription": "右側のキャプチャを選択(下)",
      "leftUpper": "左側(上)",
      "rightLower": "右側(下)"
    }
  },
  "fileUploadMessages": {
    "failedUpload": "ファイルのアップロードができませんでした。もう一度行ってください",
    "completedUpload": "アップロードが完了しました",
    "wantToUploadFile": "アップロードしますか？"
  },
  "appointmentImages": {
    "mark": "この予約にしおりをつけますか？",
    "unmark": "この予約のしおりを外しますか？",
    "uploadCompleted": "アップロードが完了しました",
    "uploadFailed": "ファイルのアップロードができませんでした。もう一度行ってください"
  },
  "shipment": {
    "title": "発送リスト",
    "shipmentSelected": "個の配送を選択済み",
    "selectAll": "全選択",
    "show": "注文",
    "hide": "お薬非表示",
    "noAppointment": "CS 無しお渡し",
    "noShipment": "配送依頼が存在しません",
    "maxShipmentsSelection": "配送が選択できます",
    "shipmentCompleteDialogTitle": "以下の今回分のお薬の発送が完了しましたか？",
    "shipmentCsvDownloadError": "配送リストダウンロード中にエラーが発生しました",
    "table": {
      "orderNo": "注文",
      "reservationTime": "診察/相談日時",
      "patientName": "患者氏名",
      "alternativeName": "患者様氏名（かな）",
      "address": "住所",
      "phoneNumber": "電話番号",
      "medicines": "薬",
      "prescription": "処方内容",
      "medicineName": "薬",
      "amount": "数量",
      "price": "金額",
      "totalPrice": "合計",
      "wayOfPayment": "支払い方法",
      "firstOnlineDelivery": "初送り"
    },
    "shipmentErrorStatuses": {
      "AlreadyShipped": "注文は既に配送されています",
      "NotPaid": "配送依頼は未払いもしくは確認されていません"
    },
    "print": {
      "createdOn": "発行日",
      "todayDeliveredMedicine": "本日のお渡し",
      "prescription": "処方箋",
      "processingSide": "処 方",
      "deliveredContent": "お渡し内容",
      "discount": "ディスカウント",
      "deduction": "値引き",
      "totalPrice": "合計",
      "dispensingDate": "調剤年月日"
    }
  },
  "trackingNumberList": {
    "trackingNumberSentList": "発送リスト",
    "trackingNumber": "追跡番号",
    "dateTimeSent": "メール送信日時",
    "noTrackedOrders": "追跡番号無し"
  },
  "trackingNumberRequestedList": {
    "title": "追跡番号依頼リスト",
    "itemsSelected": "個選択済み",
    "selectAll": "全選択",
    "enter": "入力",
    "noAppointment": "CS 無しお渡し",
    "noRequestedTrackOrders": "追跡番号依頼を含む配送はありません",
    "mailTrackingNumberDialogTitle": "追跡番号のメールをこの患者さんに送信しますか？",
    "table": {
      "orderNo": "注文",
      "reservationTime": "診察/相談日時",
      "patientName": "患者氏名",
      "alternativeName": "患者様氏名（かな）",
      "address": "住所",
      "phoneNumber": "電話番号",
      "trackingNumber": "追跡番号"
    },
    "sendMailErrors": {
      "MissingPatientEmail": "患者のメールアドレスが存在しません",
      "PatientDidNotRequestTrackingNumber": "この配送について追跡番号の表示依頼はされていません",
      "MissingTrackingNumber": "この配送に追跡番号はありません",
      "Other": "追跡番号を送信することができません"
    }
  },
  "weeklyShippedMedicines": {
    "title": "お薬消費リスト",
    "numberOfDeliveredMedicines": "お薬消費リスト",
    "total": "合計"
  },
  "orderStatistics": {
    "paidWithCreditCard": "クレジットカード",
    "paidWithElectronicMoney": "電子マネーで支払われた金額",
    "paidWithCash": "現金で支払われた金額",
    "paidInBank": "銀行振込",
    "refund": "返金",
    "totalPrice": "合計",
    "trackingRequested": "追跡No.通知希望者を表示する",
    "trackingSent": "送信済みリストを",
    "noMedicinesAndPaymentInfo": "配送された薬はありません",
    "noAppointmentOrder": "CS 無しお渡し",
    "table": {
      "appointmentStartingTime": "開始時間",
      "patientName": "患者様氏名（漢字）",
      "medicineName": "今回分のお薬",
      "amount": "数量",
      "totalPrice": "合計",
      "paymentMethod": "支払い方法"
    }
  },
  "orders": {
    "title": "確認待ちのお薬/お支払い",
    "waitingClinicPayment": "クリニック予約におけるお渡し・支払い完了待ちリスト",
    "waitingOnlinePayment": "オンライン予約における銀行振込確認待ちリスト",
    "patientName": "患者氏名",
    "orderDate": "今回分のお薬が生成された日",
    "price": "金額",
    "actions": "ステータス変更",
    "tos": "同意書",
    "print": "処方箋",
    "links": "リンク",
    "showOrdersForExpiry": "期限切れの配送依頼のみを表示",
    "unacceptedTOS": "利用規約に同意していません",
    "shouldBeExpired": "期限切れ",
    "confirmCashPayment": "現金",
    "confirmFreePayment": "お薬お渡し完了(無料)",
    "confirmBankPayment": "銀行振込確認済み",
    "confirmCardPayment": "カード",
    "confirmElectronicMoney": "電子マネー",
    "combinedPayment": "複合支払い",
    "expire": "期限切れ",
    "noOrdersPendingPayment": "支払待ちの配送依頼はありません",
    "searchOnlineOrders": "患者名で検索（ひらがな・漢字）"
  },
  "bloodAppointments": {
    "time": "時間",
    "location": "場所",
    "roomName": "予定部屋",
    "name": "患者様氏名",
    "alternativeName": "患者様氏名（かな）",
    "gender": "性別",
    "dateOfBirthAge": "生年月日 (歳)",
    "commentForNurse": "採血者申し送り",
    "noBloodAppointments": "選択された日付での採決予定はありません"
  },
  "orderType": {
    "Pickup": "来院",
    "Delivery": "オンライン"
  },
  "appointmentEvent": {
    "patientCondition": "患状",
    "lastOrderExpired": "期限切",
    "bloodExaminationStatus": "採血"
  },
  "allSalons": {
    "viewAllSalons": "全てのサロンを表示",
    "exitAllSalons": "全てのサロン表示を終了"
  },
  "patientRegistration": {
    "title": "新規申込(DB)",
    "recordsSelected": "個選択済み",
    "applicationDateAndTime": "申し込み日時",
    "desiredPeriodForFirstAppointment": "希望日",
    "preferredAppointmentLocation": "希望院",
    "patientName": "患者名",
    "patientContact": "電話番号・メール",
    "patientAffiliateId": "アフィリエイトID",
    "patientRegisteredStatus": "登録",
    "phoneCallStatus": "ステータス",
    "notes": "メモ",
    "linkToPatientDetails": "詳細",
    "noPatient": "患者は登録されていません",
    "memo": "電話メモ",
    "allMemos": "すべてのメモ",
    "addMemo": "メモの追加",
    "deleteMemoConfirmation": "メモを削除しますか？",
    "desiredConsultationPeriod": {
      "Today": "本日中",
      "TomorrowOrAfterward": "明日以降"
    },
    "contactStatus": {
      "NotSet": "-",
      "CalledOneTimeDidNotPickUp": "1回目不在",
      "CalledTwoTimesDidNotPickUp": "2回目不在",
      "CalledThreeTimesDidNotPickUp": "3回目不在",
      "Other": "その他",
      "WaitingForPhoneCall": "折り返し待ち",
      "ReservationDone": "予約完了",
      "OneWeekCancel": "1wキャンセル",
      "Cancel": "キャンセル希望",
      "NoCount": "カウント無し"
    },
    "contactStatusFilter": {
      "Incomplete": "未完了",
      "Canceled": "キャンセル済み",
      "Complete": "予約完了",
      "NoCount": "カウント無し"
    },
    "error": {
      "PhoneNumberAlreadyUsed": "重複した電話番号です（既に登録が存在します）",
      "EmailAlreadyUsed": "このメールは登録された患者に関連しています"
    }
  },
  "medicineCategory": {
    "FINA": "FINA",
    "DUTA": "DUTA",
    "MINOCK": "MINOCK",
    "MINOCK_LIQUID": "MINOCK LIQUID",
    "PANTO": "PANTO",
    "OTHER": "その他"
  }
}
