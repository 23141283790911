{
  "pageTitle": {
    "generic": "FOREST",
    "login": "Login",
    "forgotPassword": "Forgot Password",
    "resetPassword": "Reset Password",
    "employeeCalendar": "Employee Calendar",
    "printEmployeeCalendar": "Print Employee Calendar",
    "allSalons": "All salons",
    "patientSearch": "Patient Search",
    "roomSchedules": "Room Schedules",
    "workShift": "Work Shift",
    "shipment": "Shipment",
    "printShipment": "Print Shipment",
    "printLabels": "Print Labels",
    "printOrders": "Print Orders",
    "unpaidOrders": "Unpaid orders",
    "shippedMedicines": "Shipped Medicines",
    "orderStatisticsPerDay": "Order Statistics Per Day",
    "trackingNumberRequested": "Tracking Number Requested",
    "trackingNumberSent": "Tracking Number Sent",
    "addNewPatient": "New patient",
    "bloodAppointments": "Blood appointments",
    "patientRegistration": "Patient Registration",
    "patientDetails": "Patient details",
    "termsOfService": "Terms of service",
    "createAppointment": "Create Appointment",
    "editAppointment": "Edit Appointment",
    "medicalRecord": "Medical record",
    "bloodExamination": "Blood examination",
    "ordersAndPrescriptionsList": "Orders & Prescriptions list",
    "interviewSheet": "Interview Sheet",
    "mediaLibrary": "Media Library",
    "appointmentImages": "Appointment Images",
    "callScreen": "Call Screen",
    "chief": "Chief",
    "counselors": "Counselors",
    "newCounselor": "New Counselor",
    "editCounselor": "Edit Counselor",
    "concierges": "Concierge",
    "newConcierge": "New Concierge",
    "editConcierge": "Edit Concierge",
    "clinicManagers": "Clinic Managers",
    "newClinicManager": "New Clinic Manager",
    "editClinicManager": "Edit Clinic Manager",
    "doctors": "Doctors",
    "newDoctor": "New Doctor",
    "editDoctor": "Edit Doctor",
    "medicalTeam": "Medical Team",
    "newMedicalTeam": "New Medical Team",
    "editMedicalTeam": "Edit Medical Team",
    "viewers": "Viewers",
    "newViewer": "New Viewer",
    "editViewer": "Edit Viewer",
    "medicines": "Medicines",
    "newMedicine": "New Medicine",
    "editMedicine": "Edit Medicine",
    "nonWorkingDay": "Non Working Day",
    "newNonWorkingDay": "New Non Working Day",
    "editNonWorkingDay": "Edit Non Working Day",
    "workShifts": "Work Shifts",
    "notifications": "Notifications",
    "rooms": "Rooms",
    "newRoom": "New Room",
    "editRoom": "Edit Room",
    "coupons": "Coupons",
    "incomeStats": "Income Statistics",
    "clinics": "Clinics"
  },
  "calendarMode": {
    "calendarIsInEditMode": "CALENDAR IS IN EDIT MODE:",
    "timeDividerAutomatic": "Time Divider",
    "timeDividerManual": "Time Divider",
    "timeBlock": "Block Time",
    "unavailableForMobileAppointments": "Employees Unavailable For Mobile Appointments"
  },
  "timeBlockType": {
    "ClinicAppointmentsOnlyBlockTime": "Clinic only appointments",
    "BlockTime": "Block time",
    "OnlineAppointmentsOnlyBlockTime": "Online only appointments",
    "FirstVisitBlockTime": "First-visit & Extended examination only appointments",
    "LunchBreak": "Lunch break"
  },
  "reservationType": {
    "TimeDivider": "Time Divider"
  },
  "patient": {
    "searchForPatient": "Search for a patient",
    "patientsList": "Patients list",
    "resultsFound": "results found",
    "ongoing": "Ongoing",
    "follow": "Follow",
    "doctorChecked": "Doctor checked",
    "edit": "Edit",
    "name": "Name",
    "alternativeName": "Alternative name",
    "dateOfBirth": "Date of birth",
    "age": "age",
    "phoneNumber": "Phone number",
    "nextAppointmentType": "Next appointment type",
    "prescriptionStock": "Prescription Stock",
    "patientInformation": "Patient Information",
    "activity": "Activity",
    "status": "Status",
    "loggedIn": "Logged In",
    "notLoggedIn": "Not Logged In",
    "patientName": "Name",
    "patientPhoneNumber": "Patient number",
    "patientConditions": "Patient conditions",
    "conciergeNote": "Concierge Note",
    "confirmSendEmailToPatient": "Are you sure you want to send this email?",
    "deletePatientConfirmation": {
      "warningLine1": "This action cannot be undone!",
      "warningLine2": "All patient information including patient details, blood test reports, prescriptions, orders, documents, reference letters, appointments and appointment images will be deleted!",
      "warningLine3": "Do you want to delete {{patientFullName}} from FOREST?",
      "warningLine4": "Enter DELETE in the text field to confirm"
    },
    "mailOptions": {
      "header": "Choose a template",
      "infoBloodTest": "Information for blood test",
      "reserveFirstAppointment": "How to reserve first appointment",
      "shippingCodeInformation": "Shipping Information",
      "informTheTrackingNumber": "Inform the tracking number",
      "blank": "Other (blank)"
    }
  },
  "patientStatus": {
    "Verified": "Verified",
    "Test": "Test user",
    "BlockedToScheduleAppointments": "Blocked",
    "Inactive": "Inactive"
  },
  "patientIdentification": {
    "Done": "Done",
    "Required": "Check next time",
    "Unidentified": "Not yet"
  },
  "EmployeeType": {
    "Operator": "Concierge",
    "Doctor": "Doctor",
    "Counselor": "Counselor",
    "ClinicManager": "Clinic Manager",
    "StaffMember": "Medical Team",
    "Viewer": "Viewer",
    "Admin": "Admin"
  },
  "employeeSeniority": {
    "": "All",
    "Senior": "Senior",
    "Intermediate": "Intermediate",
    "Junior": "Junior"
  },
  "employeeStatus": {
    "": "All",
    "Active": "Active",
    "Inactive": "Inactive"
  },
  "calendar": {
    "today": "TODAY",
    "nonWorkingDay": "Non Working Day",
    "nonWorkingDayDetails": "It is a non working day",
    "noEmployeeSchedule": "No Employee Schedule",
    "noEmployeeScheduleDetails": "Shift has not yet been registered. (Reservation creation is possible.) When you make a reservation, a reservation will be created on the default shift.",
    "noScheduleForLoggedInEmployee": "You do not have a schedule for the selected date",
    "conflictingTimeDividersMessage": "Some time divider(s) were not created because they conflict with existing reservations. Please refresh the calendar.",
    "printCalendar": {
      "print": "PRINT",
      "togglePhoneNumber": "Toggle phone number display",
      "bloodExamination": "Blood examination",
      "startTime": "Start time",
      "appointmentType": "Appointment Type",
      "location": "Location",
      "patientNameAndAlternativeName": "patient alternative name and name",
      "patientDateOfBirthAndAge": "birth date (age)",
      "patientPhone": "Phone number",
      "roomName": "Room name",
      "counselorName": "Counselor",
      "conciergeNotes": "Note for concierge"
    }
  },
  "toolTip": {
    "refreshPage": "REFRESH PAGE",
    "print": "PRINT",
    "manageEmployees": "MANAGE EMPLOYEE",
    "timeDivider": "TIME DIVIDER",
    "timeBlock": "TIME BLOCK",
    "swap": "SWAP",
    "edit": "EDIT",
    "bloodExamination": "BLOOD EXAMINATION",
    "patientDetails": "PATIENT DETAILS",
    "medicalRecord": "MEDICAL RECORD",
    "createAppointment": "CREATE APPOINTMENT",
    "editAppointment": "EDIT APPOINTMENT",
    "delete": "DELETE",
    "mediaLibrary": "MEDIA LIBRARY",
    "interviewSheet": "INTERVIEW SHEET",
    "appointmentImages": "APPOINTMENT IMAGES",
    "ordersAndPrescriptionsList": "ORDERS AND PRESCRIPTIONS LIST",
    "callPatient": "CALL PATIENT",
    "copy": "COPY",
    "createRoomEvent": "CREATE ROOM EVENT",
    "viewDetails": "View details"
  },
  "navBar": {
    "bookingCalendar": "Booking Calendar",
    "roomSchedules": "Room Schedules",
    "shipment": "Shipment",
    "unpaidOrders": "Unpaid Orders",
    "shippedMedicines": "Shipped Medicines",
    "workShift": "Work Shift",
    "addNewPatient": "Add New Patient",
    "bloodAppointments": "Blood Appointments",
    "patientRegistration": "Patient Registration",
    "logOut": "Log Out",
    "clinicSwitcher": {
      "selectBranch": "Select the branch",
      "defaultBranch": "Default branch",
      "salon": "Salon"
    },
    "managerMenu": {
      "counselors": "Counselors",
      "concierges": "Concierge",
      "clinicManagers": "Clinic Managers",
      "doctors": "Doctors",
      "medicalTeam": "Medical Teams",
      "viewers": "Viewers",
      "medicines": "Medicines",
      "nonWorkingDays": "Non Working Days"
    }
  },
  "addAutomaticTimeDividersDialog": {
    "title": "Add Time Dividers",
    "durationTime": "Duration time(分)"
  },
  "removeAutomaticTimeDividersDialog": {
    "title": "Remove Time Dividers"
  },
  "blockTimeDialog": {
    "editBlockTime": "Edit Block Time",
    "addBlockTime": "Add Block Time",
    "blockTimeColor": "Block Time Color",
    "changeBlockTimeTitle": "Change block time title",
    "deleteBlockTime": "Are you sure you want to remove the block time?"
  },
  "extendWorkingShiftDialog": {
    "extendWorkingTime": "Extend working time",
    "workingScheduleEndTime": "Working schedule end time",
    "errorMessage": "The extended working time must end later than the currently scheduled.",
    "total": "Total",
    "maxHoursPerDay": "Max 20h per day"
  },
  "shortenWorkingShiftDialog": {
    "shortenWorkingTime": "Shorten working time",
    "workingScheduleEndTime": "Working schedule end time",
    "errorMessage": "The shortened working time must end earlier than the currently scheduled.",
    "total": "Total",
    "minMinutesPerDay": "Min 30m per day"
  },
  "endWorkingShiftDialog": {
    "endWorkingTime": "End working time",
    "workingScheduleEndTime": "Working schedule end time",
    "total": "Total",
    "minMinutesPerDay": "Min 30m per day"
  },
  "editMainInformationDialog": {
    "title": "Edit Main Information",
    "refusedToGiveEmail": "Refused to give email address"
  },
  "editAddressesDialog": {
    "title": "Manage Addresses",
    "deliverAtHome": "Deliver at home",
    "deliverAtPostOffice": "Deliver at a post office",
    "homeAddress": "Home address",
    "postCode": "Post code",
    "location": "City/town/village",
    "streetName": "Street name",
    "postOfficeAddress": "Post office address",
    "postOfficeName": "Post office name",
    "prefectureLocation": "Prefecture, city/town/village",
    "addHomeAddress": "ADD HOME ADDRESS",
    "addPostAddress": "ADD POST ADDRESS"
  },
  "editPhoneNumber": {
    "title": "Edit Phone Number"
  },
  "editPatientConditionsDialog": {
    "title": "Edit Patient Conditions"
  },
  "appointment": {
    "appointment": "Appointment",
    "location": "Location",
    "clinic": "Clinic",
    "appointmentType": "Appointment Type",
    "timeSlot": "Time slot",
    "counselorDuration": "Counselor duration",
    "doctorDuration": "Doctor duration",
    "doctorStartTimeOffset": "Doctor start time offset",
    "nextAppointmentDetails": "Next appointment details",
    "availableDoctors": "Available Doctors",
    "availableRooms": "Available Rooms",
    "lastFinishedAppointmentType": "Last finished appointment type",
    "lastFinishedAppointmentCounselor": "Last finished appointment counselor",
    "editAppointment": "EDIT APPOINTMENT",
    "appointmentDetails": "Appointment Details",
    "conciergeNoteForCommunicationLog": "A letter to the concierge (state in the communication log)",
    "minimumCounselorSeniority": "Minimum counselor seniority"
  },
  "appointmentLocation": {
    "all": "All",
    "Online": "Online",
    "Clinic": "Clinic",
    "Salon": "Salon"
  },
  "appointmentType": {
    "FirstVisit": "First Visit",
    "Consultation": "Consultation",
    "ShortConsultation": "Short Consultation",
    "Examination": "Examination",
    "ExtendedExamination": "Extended Examination",
    "TreatmentReview": "Treatment Review"
  },
  "shortAppointmentType": {
    "FirstVisit": "初",
    "Consultation": "相",
    "ShortConsultation": "相",
    "Examination": "再",
    "ExtendedExamination": "再",
    "TreatmentReview": "総"
  },
  "status": {
    "Unconfirmed": "Unconfirmed",
    "PendingPayment": "Pending Payment",
    "PendingBankPayment": "Pending Bank Payment",
    "Paid": "Paid",
    "Free": "Free",
    "FreeConfirmed": "Free Confirmed",
    "Shipped": "Shipped",
    "Canceled": "Canceled",
    "Expired": "Expired"
  },
  "appointmentOngoingActivity": {
    "null": "No activity",
    "NoActivity": "No activity",
    "WaitingCounselor": "Waiting for counselor",
    "WaitingForCounselor": "Waiting for counselor",
    "Counseling": "Counseling",
    "CounselingInProgress": "Counseling",
    "WaitingPayment": "Waiting for payment",
    "WaitingForPayment": "Waiting for payment",
    "BloodExaminationInProgress": "Blood examination in progress",
    "Finished": "Finish"
  },
  "roomEventOngoingActivity": {
    "NoActivity": "No activity",
    "Waiting": "Waiting",
    "Ongoing": "Ongoing",
    "Finished": "Finish room event"
  },
  "appointmentCancellationReason": {
    "PatientRequest": "Cancelled by patient request",
    "NoAppearance": "No appearance"
  },
  "appointmentCancellationDialog": {
    "descriptionTitle": "Do you want to cancel the reservation?",
    "description": "If you do, please let us know whether the patient notified you or not.",
    "additionalDescription": "When you do this, SMS will be sent to the patient to notify you of cancellation."
  },
  "appointmentStatus": {
    "Canceled": "Canceled",
    "Finished": "Finished",
    "Scheduled": "Scheduled"
  },
  "workShift": {
    "manageShiftSchedule": "Manage shift schedule",
    "shiftManagement": "Shift management",
    "viewSchedule": "View schedule",
    "uploadShiftButton": "Import file",
    "fileUploadText": "File uploaded on",
    "noShift": "No Shift",
    "assignWorkShiftToPeriod": "Assign work shifts to a period",
    "assignWorkShiftToPeriodInfo": "Have in mind that work shifts can be applied automatically to a period longer than a week.",
    "selectWorkShift": "Select work shift",
    "selectEmployee": "Select employee",
    "fromDate": "From",
    "toDate": "To",
    "assignWorkShifts": "Assign work shifts"
  },
  "confirmationDialog": {
    "unsavedChangesText": "You have not saved your changes. Are you sure you want to discard the changes you made?",
    "deleteAddress": "Are you sure you want to delete this address?"
  },
  "dailyNotesDialog": {
    "placeholder": "Write a new note here",
    "title": "Daily Notes",
    "deleteDailyNote": "Are you sure you want to remove the daily note?"
  },
  "createEditAppointment": {
    "newTitle": "Create New Appointment",
    "editTitle": "Edit Appointment",
    "cancelWarningMessage": "Are you sure you want to cancel the appointment? Be aware that all information you added previously will be lost.",
    "createAppointmentMessage": "Do you really want to create this appointment?",
    "doctorModalInfoText": "Please pick one counselor to select a time slot and see the doctors available for that period.",
    "noDoctorsAvailable": "No available doctors for this time",
    "doNotSendSmsAndEmailNotificationToPatient": "Do not send SMS and email notification to patient"
  },
  "prescription": {
    "prescriptionUpdateNotificationMessage": "Prescription will be updated in the MR."
  },
  "date": {
    "startTime": "Start Time",
    "endTime": "End Time",
    "year": "Year",
    "month": "Month",
    "day": "Day",
    "minute": "Minute"
  },
  "form": {
    "name": "Name",
    "surname": "Surname",
    "alternativeName": "Alternative Name",
    "alternativeSurname": "Alternative Surname",
    "patientName": "Name",
    "patientSurname": "Surname",
    "patientAlternativeName": "Alternative Name",
    "patientAlternativeSurname": "Alternative Surname",
    "birthday": "Birthday",
    "device": "Device",
    "gender": "Gender",
    "phoneNumber": "Phone Number",
    "orderNo": "Order No",
    "email": "Email",
    "clinic": "Clinic",
    "medicineCategory": "Medicine Category",
    "date": "Date",
    "type": "Type",
    "filter": "Filter",
    "search": "search",
    "status": "Status",
    "slackId": "Slack ID",
    "roomNumber": "Room Number",
    "roomName": "Room name",
    "location": "Location",
    "password": "Password",
    "repeatPassword": "Repeat password",
    "assignee": "Assignee",
    "selectOption": "Select from list",
    "medicineName": "Medicine name",
    "secondName": "Second name",
    "amount": "Amount",
    "price": "Price",
    "all": "All",
    "none": "None",
    "active": "Active",
    "inactive": "Inactive",
    "onlinePrice": "Online price",
    "termsOfService": "Terms of service",
    "isPrescribable": "Is prescribable",
    "available": "Available",
    "unavailable": "Unavailable",
    "fromDate": "From",
    "toDate": "To",
    "hasChangedMessage": "There are edits on other pages. Do you want to reflect what was edited on other pages? \nPressing OK opens a new tab, and the one edited on the other page is reflected there. The contents edited by yourself will not be reflected automatically, please paste it again with copy and paste.",
    "patientSearchPlaceHolder": "Enter name to find patient",
    "medicineSearchPlaceHolder": "Enter name to find medicine",
    "yen": "¥",
    "purchasableInSubscriptionPlan": "Purchasable in subscription plan"
  },
  "manageTimeDividers": {
    "question": "How do you want to manage time dividers?",
    "automatically": "Automatically",
    "manually": "Manually"
  },
  "adjustWorkShift": {
    "tooltip": "ADJUST WORKING TIME",
    "extend": "Extend",
    "shorten": "Shorten",
    "end": "End"
  },
  "genericButtons": {
    "add": "ADD",
    "done": "DONE",
    "save": "SAVE",
    "saveChanges": "SAVE CHANGES",
    "saveAll": "SAVE ALL",
    "yes": "YES",
    "yes2": "YES",
    "no": "NO",
    "no2": "NO",
    "ok": "OK",
    "finish": "FINISH",
    "start": "START",
    "confirm": "CONFIRM",
    "selectAll": "SELECT ALL",
    "deselectAll": "DESELECT ALL",
    "cancel": "CANCEL",
    "close": "CLOSE",
    "discard": "DISCARD",
    "discard2": "DISCARD",
    "print": "PRINT",
    "refund": "REFUND",
    "back": "BACK",
    "next": "NEXT",
    "remove": "REMOVE",
    "applyAndSave": "APPLY & SAVE",
    "clearAll": "CLEAR ALL",
    "showList": "Show list",
    "hideList": "Hide list",
    "viewMore": "View more",
    "loadMore": "Load more",
    "viewLess": "View less",
    "edit": "EDIT",
    "scrollToTop": "TOP",
    "clearFilter": "CLEAR FILTER",
    "filter": "FILTER",
    "confirmCash": "CONFIRM CASH",
    "confirmCardPayment": "CONFIRM CARD PAYMENT",
    "confirmElectronicMoney": "CONFIRM ELECTRONIC MONEY",
    "combinedPayment": "COMBINED PAYMENT",
    "confirmFreeDelivery": "CONFIRM FREE DELIVERY",
    "confirmBankPayment": "CONFIRM BANK PAYMENT",
    "expired": "EXPIRED",
    "complete": "COMPLETE",
    "activate": "ACTIVATE",
    "deactivate": "DEACTIVATE",
    "proceed": "PROCEED",
    "printAllShipment": "PRINT ALL AS SHIPMENT",
    "printSelectedShipment": "PRINT SELECTED AS SHIPMENT",
    "printAllLabels": "PRINT ALL LABELS",
    "printSelectedLabels": "PRINT SELECTED LABELS",
    "printAllOrders": "PRINT ALL ORDERS",
    "printSelectedOrders": "PRINT SELECTED ORDERS",
    "exportAsCsv": "EXPORT AS CSV",
    "exportAllSelectedAsCsv": "EXPORT SELECTED ITEMS TO CSV",
    "shipmentComplete": "SHIPMENT COMPLETE",
    "mailTrackingNumber": "MAIL TRACKING NUMBER",
    "create": "CREATE",
    "delete": "DELETE",
    "callDoctor": "CALL DOCTOR",
    "register": "REGISTER"
  },
  "genericText": {
    "canceled": "canceled",
    "changed": "changed",
    "comment": "Comment",
    "confirmation": "Are you sure you want to close without saving changes?",
    "unsavedChangesDialogMessage": "You have unsaved changes. Do you want to proceed?",
    "showAll": "Show all",
    "emailSent": "Email Sent !"
  },
  "snackbar": {
    "errorTitle": "Error"
  },
  "logIn": {
    "title": "Use your e-mail and password to log in",
    "logIn": "Log In",
    "forgotPassword": "Forgot your password?"
  },
  "forgotPassword": {
    "title": "Enter your email to recover your password",
    "send": "Send"
  },
  "resetPassword": {
    "title": "Enter a new password that has at least 8 characters",
    "setPassword": "Set password"
  },
  "admin": {
    "notifications": {
      "types": {
        "BULK": "Bulk",
        "BULK_APPOINTMENT": "Appointment bulk",
        "BULK_ANNOUNCEMENT": "Announcement bulk",
        "SINGLE_PATIENT": "Single Patient"
      },
      "newNotification": "New Notification",
      "notifications": "Notifications",
      "notificationType": "Notification type",
      "sendNotificationAt": "Send Notification At",
      "fromDate": "From",
      "toDate": "To",
      "patientSearch": "Patient Search",
      "patientSearchPlaceHolder": "Enter name to find patient",
      "phoneNumber": "Phone number",
      "url": "url",
      "title": "Title",
      "body": "Body",
      "notificationsNotFound": "No notifications available",
      "deleteConfirmation": "Are you sure you want to delete this notification?"
    },
    "workShift": {
      "workShifts": "Work Shifts",
      "name": "Name",
      "startsAt": "Starts At",
      "endsAt": "Ends At",
      "availableDays": "Available Days",
      "clinics": "Clinics",
      "salon": "Salon",
      "timeBlockStartsAt": "Time Block Starts At",
      "timeBlockDurationInMinutes": "Duration in minutes",
      "lunchBreakStartsAt": "Lunch Break Starts At",
      "lunchBreakDurationInMinutes": "Duration in minutes",
      "active": "Active",
      "inactive": "Inactive",
      "edit": "Edit",
      "createWorkShift": "Create Work Shift",
      "interval": "Interval",
      "workShiftsNotFound": "No Work shifts available",
      "addTimeBlock": "Add Time Block",
      "removeTimeBlock": "Remove Time Block",
      "addLunchBreak": "Add Lunch Break",
      "removeLunchBreak": "Remove Lunch Break",
      "AvailableDays": {
        "Weekend": "Weekend",
        "Weekday": "Weekday",
        "Any": "Any"
      }
    },
    "rooms": {
      "rooms": "Rooms",
      "editRoom": "Edit Room",
      "createRoom": "Create Room",
      "createRoomTooltip": "CREATE ROOM",
      "allowPatientMadeAppointments": "Allow patient made appointments",
      "confirmDeactivateRoom": "Are you sure you want to deactivate this room?",
      "roomsNotFound": "There are no rooms created for this clinic",
      "roomStatus": {
        "Active": "Active",
        "Inactive": "Inactive"
      },
      "availableForPatientMadeAppointments": {
        "yes": "Yes",
        "no": "No"
      }
    },
    "coupons": {
      "coupons": "Coupons",
      "code": "Coupon code",
      "type": "Type",
      "value": "Value",
      "status": "Status",
      "target": "Target",
      "patient": "Patient",
      "validUntil": "Valid until",
      "orderType": "Order Type",
      "trackUsage": "Track Usage",
      "edit": "Edit",
      "medicine": "Medicine name",
      "createCoupon": "Create Coupon",
      "maxUses": "Maximum Uses",
      "couponsNotFound": "There are no coupons",
      "couponCodePatternError": "Only underscore, numeric and capital letters are allowed",
      "couponType": {
        "FixedCost": "Fixed Cost",
        "PercentageCost": "Percentage Cost",
        "MedicineCost": "Medicine Cost",
        "FreeShipping": "Free Shipping"
      },
      "couponTarget": {
        "SINGLE_PATIENT": "Single patient",
        "ALL_PATIENTS": "All patients",
        "MULTIPLE_PATIENTS": "Multiple patients"
      },
      "couponUsage": {
        "OVERALL": "Overall",
        "PER_PATIENT": "Per Patient",
        "ONCE_PER_PATIENT": "Once Per Patient"
      }
    },
    "incomeStats": {
      "incomeStats": "Income Statistics",
      "download": "Download",
      "type": {
        "TotalIncomePerDay": "Total income per day",
        "TotalIncomePerPatient": "Total income per patient"
      }
    },
    "clinics": {
      "clinics": "Clinics/Salons",
      "name": "Clinic name",
      "createClinic": "Create Clinic/Salon",
      "editClinic": "Edit Clinic/Salon",
      "clinicType": "Clinic type",
      "webLandingPageAvailability": "Web landing page availabilty",
      "mobileAvailability": "Mobile availability",
      "activate": "Activate",
      "deactivate": "Deactivate",
      "opensAt": "Opens At",
      "closesAt": "Closes At",
      "primaryColorHex": "Color",
      "address": "Address",
      "phoneNumber": "Phone Number",
      "website": "Website",
      "latitude": "Latitude",
      "longitude": "Longitude",
      "supervisorDoctor": "Supervisor Doctor",
      "doctorSearchPlaceholder": "Search for doctor",
      "directorName": "Director Name",
      "orderNo": "Order No",
      "generalChannelId": "General Channel ID",
      "uploadClinicImage": "Upload Clinic Image",
      "createClinicTooltip": "Create Clinic/Salon",
      "types": {
        "Regular": "Regular",
        "Salon": "Salon"
      },
      "webLandingPageAvailabilityConfirmationMessage": {
        "activate": "Are you sure you want to activate {{clinicName}} on the web landing page?",
        "deactivate": "Are you sure you want to deactivate {{clinicName}} on the web landing page?"
      },
      "mobileAvailabilityConfirmationMessage": {
        "activate": "Are you sure you want to activate {{clinicName}} on mobile?",
        "deactivate": "Are you sure you want to deactivate {{clinicName}} on mobile?"
      }
    }
  },
  "manager": {
    "confirmDeactivateUser": "Are you sure you want to disable this account?",
    "confirmRemoveNonWorkingDay": "Are you sure you want to remove this non working day?",
    "searchFilters": {
      "enterSearchParameters": "Enter search parameters",
      "status": "Status",
      "type": "Type"
    },
    "table": {
      "name": "Name",
      "secondName": "Second name",
      "medicineName": "Medicine name",
      "email": "E-Mail",
      "clinic": "Clinic",
      "slackId": "Slack ID",
      "status": "Status",
      "type": "Type",
      "amount": "Amount",
      "price": "Price",
      "onlinePrice": "Online price",
      "date": "Date",
      "location": "Location",
      "available": "Available",
      "availableForPatientMadeAppointments": "Available for patient made appointments",
      "edit": "Edit"
    }
  },
  "counselor": {
    "addCounselor": "Add Counselor",
    "editCounselor": "Edit Counselor",
    "type": "Counselor Type",
    "applyConciergeAccess": "Apply Concierge Access"
  },
  "counselorList": {
    "header": {
      "title": "Counselors",
      "createCounselorTooltip": "CREATE COUNSELOR"
    },
    "counselorsNotFound": "There are no counselors created for this clinic"
  },
  "doctor": {
    "addDoctor": "Add Doctor",
    "editDoctor": "Edit Doctor",
    "uploadSignature": "Upload Signature",
    "type": "Doctor Type"
  },
  "doctorsList": {
    "header": {
      "title": "Doctors",
      "createDoctorTooltip": "CREATE DOCTOR"
    },
    "doctorsNotFound": "There are no doctors created for this clinic"
  },
  "concierge": {
    "addConcierge": "Add Concierge",
    "editConcierge": "Edit Concierge",
    "applyCounselorAccess": "Apply Counselor Access"
  },
  "conciergesList": {
    "header": {
      "title": "Concierges",
      "createConciergeTooltip": "CREATE CONCIERGE"
    },
    "conciergesNotFound": "There are no concierges created for this clinic"
  },
  "clinicManager": {
    "addClinicManager": "Add Clinic Manager",
    "editClinicManager": "Edit Clinic Manager"
  },
  "clinicManagersList": {
    "header": {
      "title": "Clinic Managers",
      "createClinicManagerTooltip": "CREATE CLINIC MANAGER"
    },
    "clinicManagersNotFound": "There are no clinic managers created for this clinic"
  },
  "medicalTeam": {
    "addMedicalTeam": "Add Medical Team Member",
    "editMedicalTeam": "Edit Medical Team Member"
  },
  "medicalTeamList": {
    "header": {
      "title": "Medical Team",
      "createMedicalTeamTooltip": "CREATE MEDICAL TEAM MEMBER"
    },
    "medicalTeamNotFound": "There are no medical team created for this clinic"
  },
  "viewer": {
    "addViewer": "Add Viewer",
    "editViewer": "Edit Viewer"
  },
  "viewersList": {
    "header": {
      "title": "Viewers",
      "createViewerTooltip": "CREATE VIEWER"
    },
    "viewerNotFound": "There are no viewers created for this clinic"
  },
  "medicinesList": {
    "header": {
      "title": "Medicines",
      "createMedicineTooltip": "CREATE MEDICINE"
    },
    "isAvailable": {
      "yes": "Yes",
      "no": "No"
    },
    "addMedicine": "Add medicine",
    "editMedicine": "Edit Medicine",
    "medicinesNotFound": "There are no medicines for this clinic",
    "quantityLimited": "Limited medicines for orders without consultation",
    "availableForPatientMadeOrdersWithoutConsultation": "Available for patient purchase without consultation",
    "purchasableInSubscriptionPlan": "Purchasable in Subscription Plan"
  },
  "nonWorkingDay": {
    "addNonWorkingDay": "Add Non Working Day",
    "editNonWorkingDay": "Edit Non Working Day"
  },
  "nonWorkingDaysList": {
    "header": {
      "title": "Non Working Days",
      "createNonWorkingDayTooltip": "CREATE NON WORKING DAY"
    },
    "nonWorkingDayNotFound": "There are no non working days created for this clinic"
  },
  "createPatient": {
    "title": "Add New Patient"
  },
  "patientInformation": {
    "noteInputPlaceholder": "No message",
    "forPatient": "For Patient",
    "forConcierge": "For Concierge",
    "forCounsellor": "For Counsellor",
    "mainInformation": "Main Information",
    "addresses": "Addresses",
    "automaticNotifications": "Automatic Notifications",
    "notifyPatient": "Notify patient via sms, email, push notifications",
    "doNotNotifyPatient": "Do not notify patient via sms, email, push notifications",
    "on": "On",
    "off": "Off",
    "patientStatus": "Patient Status",
    "identification": "Identification",
    "homeDelivery": "Home Delivery",
    "postOfficeDelivery": "Post Office Delivery",
    "reservationLogs": "Reservation Logs",
    "reservations": "Reservations",
    "interviewSheet": "Interview Sheet",
    "ordersAndPrescriptionsList": {
      "title": "Orders and Prescriptions List",
      "orders": "Orders",
      "prescriptions": "Prescriptions",
      "status": "Status",
      "pending": "Pending payment",
      "medicines": "Medicines",
      "totalPrice": "Total price",
      "showMedicines": "Show medicines",
      "hideMedicines": "Hide medicines",
      "createdByPatient": "Created by patient",
      "table": {
        "medicineName": "Medicine name",
        "amount": "Amount",
        "price": "Price",
        "refunded": "Refunded"
      },
      "comment": "Comment",
      "totalDiscount": "Total Discount",
      "cancellationConfirm": "Do you really want to cancel the order?",
      "refundConfirm": "Do you really want to create this refund?",
      "orderListEmpty": "There are no orders created for this patient",
      "prescriptionListEmpty": "There are no prescriptions created for this patient",
      "refundCreatedOn": "Refund created on:",
      "customRefundAmount": "Custom refund amount:",
      "totalRefundAmount": "Total refund amount:"
    },
    "consentForm": "Consent Form",
    "contact": {
      "title": "Contact",
      "preview": "Preview",
      "contactSelectedFor": "contact(s) selected for",
      "enterContact": "Enter contact",
      "archive": "Archive",
      "restore": "Restore",
      "archivedLogs": "Archived logs",
      "contactLog": "Contact log",
      "emptyContacts": "No contacts yet",
      "type": {
        "Inquiry": "Inquiry",
        "Appointment": "Appointment",
        "TroubleAboutService": "Trouble About Service",
        "TroubleAboutSystem": "Trouble About System",
        "Other": "Other",
        "CancelAppointment": "Cancel Appointment",
        "DeleteAppointment": "Delete Appointment",
        "EditAppointment": "Edit Appointment",
        "Payment": "Payment",
        "MistakeFromClinic": "Mistake From Clinic"
      },
      "method": {
        "PhoneCall": "Phone Call",
        "InTheClinic": "In The Clinic",
        "Application": "From App",
        "Forest": "From FOREST",
        "Others": "Others",
        "ReceivePhoneCall": "Receive Phone Call",
        "MakePhoneCall": "Make Phone Call",
        "InPerson": "In Person",
        "WebApp": "Web App",
        "AppointmentForm": "Appointment Form"
      },
      "contactDialog": {
        "enterContact": "Add Contact",
        "branch": "Branch",
        "dateOfOccurrence": "Date of occurrence",
        "timeOfOccurrence": "Time of occurrence",
        "contactMethod": "Contact method",
        "comment": "Comment",
        "post": "Post",
        "enterTextMessage": "Please enter text"
      }
    },
    "contactTable": {
      "date": "Date",
      "type": "Type",
      "time": "Time",
      "contactMethod": "Contact method",
      "responsible": "Responsible",
      "comment": "Comment",
      "dateAndTimeOfReservation": "Date and time of reservation",
      "archive": "archive",
      "restore": "restore",
      "appointmentCancelledByPatientRequest": "Cancelled by patient request"
    },
    "reservationTable": {
      "order": "Order",
      "location": "Location",
      "dateAndTimeOfReservation": "Date and time of reservation",
      "type": "Appointment Type",
      "counselor": "Counselor",
      "status": "Status",
      "edit": "Edit"
    },
    "medicines": {
      "title": "Medicines",
      "medicinesTabFilter": {
        "PrescriptionDrugs": "Prescription Drugs",
        "CompletedOrders": "Completed Orders",
        "AllOrders": "All Orders",
        "Refund": "Refund"
      },
      "quantity": "Quantity",
      "physician": "Physician",
      "patientMade": "Created by patient",
      "tos": "TOS",
      "edit": "Edit"
    },
    "order": {
      "preparationDate": "Preparation Date",
      "status": "Status",
      "price": "Price",
      "paymentConfirmationDate": "Payment Confirmation Date",
      "shipmentDate": "Shipment Date",
      "paymentDate": "Payment Date",
      "paymentMethod": "Payment Method",
      "location": "Location",
      "refundDate": "Refund Date",
      "amount": "Amount",
      "content": "Content",
      "editPaymentMethod": "Edit payment method",
      "totalPrice": "Total price",
      "theWayOfPayment": "The way of payment",
      "creditCard": "Credit card",
      "cash": "Cash",
      "electronicMoney": "Electronic money"
    }
  },
  "ContactUpdateMode": {
    "archive": "archive",
    "unArchive": "unArchive"
  },
  "deliveryLocation": {
    "Home": "home",
    "PostOffice": "post"
  },
  "paymentMethod": {
    "CARD": "credit card",
    "BANK": "bank",
    "CLINIC": "clinic",
    "CASH": "cash",
    "ELECTRONIC": "electronic money",
    "MIXED": "mixed"
  },
  "consentForm": {
    "openConsentForm": "Open consent form",
    "agree": "Agree",
    "disagree": "Disagree",
    "tosAcceptedMessage": "Thank you for the consent.\n Please return the device to staff."
  },
  "gender": {
    "Female": "Female",
    "Male": "Male",
    "NotDefined": "Not Defined"
  },
  "deviceType": {
    "Ios": "iOS",
    "Android": "Android",
    "DeviceNotDefined": "DeviceNotDefined",
    "NotAsked": "NotAsked"
  },
  "bloodExamination": {
    "title": "Blood Examination Results",
    "createNew": "CREATE NEW",
    "createNewExamination": "Create new blood examination",
    "emptyListMsg": "There are no blood examinations",
    "contactDate": "Contact Date",
    "contact": "Contact",
    "patientContact": {
      "contactRequired": "Contact Required",
      "contactNotRequired": "Contact Not Required"
    },
    "contactComment": "Contact Comment",
    "dayOfBloodExamination": "Day of blood examination",
    "status": "Status",
    "trouble": "Trouble",
    "troubleValue": {
      "had_trouble": "Had trouble",
      "no_trouble": "No trouble"
    },
    "nameOfNurse": "Name of nurse",
    "dayTheResultWasChecked": "Day the result was checked",
    "doctorCheckedTheResult": "Doctor checked the result",
    "possibleOrNot": "Possible or not",
    "nextBloodExaminationPeriod": "Next blood examination period",
    "nextSpecialBloodExaminationPeriod": "Special next blood examination",
    "nextBloodExaminationTitle": "Next Blood Examination",
    "specialType": "special",
    "regularType": "regular",
    "liver": "Liver",
    "renal": "Renal",
    "special": "Special",
    "female": "Female",
    "female-BIMAN-type": "female (BIMAN-type)",
    "bloodExaminationComment": "Comment",
    "bloodExaminationNurseComment": "Comment for nurse",
    "confirmDeleteDialogMessage": "Are you sure you want to delete this blood examination data?",
    "editButton": "EDIT",
    "cancelButton": "CANCEL",
    "submitButton": "SUBMIT",
    "updateButton": "UPDATE",
    "toastMessages": {
      "newBloodExaminationCreated": "Created new blood examination",
      "newBloodExaminationNotCreated": "New blood examination was not created",
      "bloodExaminationHasBeenUpdated": "Blood examination has been updated",
      "bloodExaminationHasNotBeenUpdated": "Blood examination has not been updated",
      "bloodExaminationDeleted": "Blood examination successfully deleted"
    },
    "height": "Height",
    "weight": "Weight",
    "deleteBloodExamination": "Are you sure you want to remove the blood examination?"
  },
  "bloodExaminationStatus": {
    "WaitingForResults": "Waiting for results",
    "Optional": "Optional",
    "Skip": "Skip",
    "Error": "Error/Deficiency Exist",
    "HavingResults": "Having Results",
    "ResultsExplained": "Results explained"
  },
  "bloodExaminationTrouble": {
    "noTrouble": "No trouble",
    "hadTrouble": "Trouble"
  },
  "bloodExaminationPossible": {
    "possibleNoLetterOfReference": "Possible no letter of ref",
    "possibleHaveLetterOfReference": "Possible have letter of ref",
    "notPossibleLetterOfReference": "Not possible letter of ref",
    "notPossibleHaveLetterOfReference": "Not possible have letter of ref"
  },
  "nextBloodExaminationPeriod": {
    "InOneYear": "In one year",
    "InHalfAYear": "In half a year",
    "InThreeMonths": "In three months",
    "letterOfReference": "Letter of ref",
    "letterOfReferenceNoImprovementsInNextAppointment": "Letter of ref no improvements"
  },
  "sideEffect": {
    "ongoing": "Ongoing",
    "viewDetails": "View details"
  },
  "sideEffectOptions": {
    "Follow": "Follow",
    "DoNotFollow": "Do not follow"
  },
  "medicalRecord": {
    "appointmentList": {
      "compare": "Compare"
    },
    "appointmentRecordHasBeenUpdated": "The latest appointment has been updated",
    "callDoctorInfo": "Message was sent!",
    "earlyAppointmentCallConfirmation": "It's before the appointment starting time. Are you sure you want to make a video call?",
    "changeRoomStatusToOngoing": "Please change the room status to “ongoing” before you call the patient.",
    "examination": "Examination",
    "prescriptionAndDelivery": "Prescription & Delivery",
    "editQuestions": "EDIT QUESTIONS",
    "saveQuestions": "SAVE QUESTIONS",
    "copyGeneralSectionFromPreviousAppointmentButton": "Copy from the previous general section",
    "copyGeneralSectionFromPreviousAppointmentConfirmation": "The contents that you write will be disappeared because the previous contents will overwrite here. Are you sure you want to paste the contents here?",
    "finishAppointmentConfirmationMessage": "Are you sure you want to finish the appointment? (You can not revert this operation)",
    "viewDetails": "View details",
    "viewPatientStock": "View patient stock",
    "heartStats": {
      "top": "top",
      "bottom": "bottom",
      "pressure": "Pressure",
      "pulse": "Pulse"
    },
    "generalSection": {
      "area": "Area",
      "areaOptions": {
        "Hokkaido": "Hokkaido",
        "Aimori": "Aimori",
        "Iwate": "Iwate",
        "Miyagi": "Miyagi",
        "Akita": "Akita",
        "Yamagata": "Yamagata",
        "Fukushima": "Fukushima",
        "Ibaraki": "Ibaraki",
        "Tochigi": "Tochigi",
        "Gunma": "Gunma",
        "Saitama": "Saitama",
        "Tokyo": "Tokyo",
        "ChibaKen": "Chiba Ken",
        "Kanagawa": "Kanagawa",
        "Niigata": "Niigata",
        "Toyama": "Toyama",
        "Ishikawa": "Ishikawa",
        "Fukui": "Fukui",
        "Yamanashi": "Yamanashi",
        "Nagano": "Nagano",
        "Gifu": "Gifu",
        "Shizuoka": "Shizuoka",
        "Aichi": "Aichi",
        "Mie": "Mie",
        "Shiga": "Shiga",
        "Kyoto": "Kyoto",
        "Osaka": "Osaka",
        "Hyogo": "Hyogo",
        "Nara": "Nara",
        "Wakayama": "Wakayama",
        "Tottori": "Tottori",
        "Shimane": "Shimane",
        "Okayama": "Okayama",
        "Hiroshima": "Hiroshima",
        "Yamaguchi": "Yamaguchi",
        "Tokushima": "Tokushima",
        "Kagawa": "Kagawa",
        "Ehime": "Ehime",
        "Kouchi": "Kouchi",
        "Fukuoka": "Fukuoka",
        "Saga": "Saga",
        "Nagasaki": "Nagasaki",
        "Kumamoto": "Kumamoto",
        "Oita": "Oita",
        "Miyazaki": "Miyazaki",
        "Kagoshima": "Kagoshima",
        "Okinawa": "Okinawa",
        "Overseas": "Overseas / Other",
        "undefined": ""
      },
      "diagnosis": "Diagnosis",
      "pastDiagnosis": "Past Diagnosis",
      "diagnosisOptions": {
        "type_II": "type Ⅱ",
        "type_IIv-I": "type Ⅱv - Ⅰ",
        "type_IIv-II": "type Ⅱv - Ⅱ",
        "type_IIv-III": "type Ⅱv - Ⅲ",
        "type_IIa": "type IIa",
        "type_III": "type Ⅲ",
        "type_IIIv-I": "type Ⅲv - Ⅰ",
        "type_IIIv-II": "type Ⅲv - Ⅱ",
        "type_IIIa": "type IIIa",
        "type_IV": "type Ⅳ",
        "type_IVa": "type Ⅳa",
        "type_V": "type Ⅴ",
        "type_Va": "type Ⅴa",
        "type_VI": "type Ⅵ",
        "type_VII": "type Ⅶ",
        "type_ludwigI": "type_ludwigI",
        "type_ludwigII": "type_ludwigII",
        "type_ludwigIII": "type_ludwigIII",
        "undefined": "",
        "type_IIv-IIa": "type_IIv-IIa",
        "type_IIv-IIIa": "type_IIv-IIIa",
        "type_IIIv-IIa": "type_IIIv-IIa"
      },
      "treatment": "Treatment",
      "treatmentOpen": "Open",
      "concerningMatters": "Concerning Matters",
      "concerningMattersOptions": {
        "top": "top",
        "edge": "edge",
        "whole": "whole",
        "front": "front",
        "partition": "partition",
        "prevention": "prevention",
        "fallingDownHair": "falling down hair",
        "volume": "volume"
      },
      "importantNote": "Important Note",
      "copyFromPreviousAppointmentButton": "Copy from the previous general section",
      "copyFromPreviousAppointmentConfirmation": "The contents that you write will be disappeared because the previous contents will overwrite here. Are you sure you want to paste the contents here?"
    },
    "notesForGeneralSection": "General Notes",
    "examinationSection": {
      "consultationType": {
        "consultationTypeQuestion": "Consultation Type",
        "doctorChangeQuestion": "If they changed doctor",
        "title": "Consultation Type",
        "consultationTypeOptions": {
          "pleaseSelect": "--Please Select--",
          "onlyConsultation": "only consulting",
          "examinationWithoutPrescription": "examination/no prescription",
          "examinationWithPrescription": "examination/prescription",
          "none": "None"
        }
      },
      "firstVisitHeadSkinSymptomsSection": {
        "dandruff": "Dandruff",
        "itching": "Itching",
        "other": "Other",
        "title": "Subjective symptoms for the head skin"
      },
      "headSkinSymptomsSection": {
        "title": "Subjective symptoms for the head skin",
        "healthConditionQuestion": {
          "title": "Change of the health condition",
          "options": {
            "yes": "yes",
            "no": "no"
          }
        },
        "hairLossQuestion": {
          "title": "Hair loss",
          "options": {
            "increase": "increase",
            "same": "same",
            "decrease": "decrease",
            "notSure": "not sure",
            "initialHairLossOngoing": "initial hair loss (ongoing)",
            "initialHairLossFinished": "initial hair loss (finished)"
          }
        },
        "glossAndStiffnessQuestion": {
          "title": "Gloss and stiffness",
          "options": {
            "increase": "increase",
            "same": "same",
            "decrease": "decrease",
            "notSure": "not sure"
          }
        },
        "awarenessOfHairIncreaseQuestion": {
          "title": "Awareness of hair increase",
          "options": {
            "increaseEdgeLine": "increase(edge line)",
            "increaseTop": "increase(top)",
            "same": "same",
            "decrease": "decrease",
            "notSure": "not sure",
            "downyHair": "downy hair"
          }
        },
        "bodyHairQuestion": {
          "title": "Body hair",
          "options": {
            "increase": "increase",
            "same": "same"
          }
        }
      },
      "prescriptionsSection": {
        "title": "Prescriptions and Deliveries",
        "prescriptionComment": "Prescription:",
        "deliveryComment": "Delivery:"
      },
      "extendedExaminationSummarySection": {
        "title": "Summary of the extended examination",
        "previousExtendedExaminationComparisonComment": {
          "title": "Previous extended examination comparison from pictures"
        },
        "degreeOfSatisfactionComment": {
          "title": "Degree of satisfaction",
          "overallSatisfactionTitle": "Overall satisfaction",
          "effectOfTreatmentTitle": "Effect of treatment",
          "satisfactionOfServiceTitle": "Satisfaction of service"
        },
        "patientRequestQuestion": {
          "title": "Patient's request",
          "options": {
            "keep": "keep",
            "increase": "increase",
            "others": "others"
          }
        },
        "mgpaScoreQuestion": {
          "title": "MGPA score"
        }
      },
      "firstImpressionFromSkinCheckSection": {
        "title": "First impression from skin check",
        "exanthema": "Exanthema",
        "flare": "Flare",
        "pimple": "Pimple",
        "dandruff": "Dandruff"
      },
      "photoComparisonSection": {
        "title": "Photo comparison",
        "overallStiffnessQuestion": {
          "title": "gloss, stiffness, transparent"
        },
        "headTopQuestion": {
          "title": "top"
        },
        "hairOfHairlineQuestion": {
          "title": "downy hair on the edge, M line"
        },
        "exanthemaQuestion": {
          "title": "exanthema"
        },
        "options": {
          "increase": "increase",
          "same": "same",
          "decrease": "decrease"
        }
      },
      "photographicFindingsSection": {
        "title": "Photographic findings",
        "top": "Top",
        "topOfTheHeadQuestions": {
          "range": "[Range]",
          "degree": "[Degree]"
        },
        "hairLine": "Hair line",
        "hairLineQuestions": {
          "goingBack": "[Going back]",
          "inDistinct": "[In distinct]",
          "degreeOfGoingBack": "[Degree of going back]",
          "rangeOfGoingBack": "[Range of going back]"
        }
      },
      "bloodPressureAndPulseSection": {
        "title": "Blood Pressure / Pulse",
        "upperBloodPressure": "Top",
        "lowerBloodPressure": "Bottom",
        "pulse": "Pulse",
        "unusualTestResult": "Unusual result of past blood pressure, electro cardiogram, roentgen",
        "regularChecks": "Whether he has regular health check or no",
        "unusualResultsFromPastChecks": "Unusual result from past health check",
        "yes": "Yes",
        "no": "No"
      },
      "pastExperienceOfHairlossTreatmentSection": {
        "title": "Treatment",
        "yes": "Yes",
        "no": "No"
      },
      "supplementSection": {
        "title": "Supplement",
        "yes": "Yes",
        "no": "No"
      },
      "newTreatmentPlanSection": {
        "title": "New treatment plan",
        "multipleMonthsQuestion": {
          "title": "if the patient wants to get multiple months"
        },
        "yes": "Yes",
        "no": "No"
      },
      "patientAimSection": {
        "title": "What patient wants to achieve",
        "increaseAmount": "Increase the amount",
        "keepAmount": "Keep the current amount"
      },
      "medicalHistorySection": {
        "title": "Medical History",
        "yes": "Yes",
        "no": "No",
        "householdMedicine": "Household medicine",
        "pastHistoryOfProstaticCancerInFamily": "Past history of prostatic cancer in the family members",
        "illHealth": "Ill Health",
        "allergyToMedicine": "Allergy to medicine",
        "pregnancy": "(only for female) Pregnancy",
        "pregnancyOptions": {
          "yes": "Yes",
          "no": "No",
          "possible": "Possible"
        }
      },
      "nextAppointmentDetailsSection": {
        "nextAppointmentType": "Next Appointment Type",
        "finishExamination": "FINISH EXAMINATION",
        "makeBloodExaminationOnNextAppointment": "Make blood examination on next appointment",
        "assignExperiencedCounselorOnNextAppointment": "Assign intermediate or senior counselor on next appointment",
        "finishExaminationConfirmationMessage": "Do you want to finish the examination? (You can not revert this operation)"
      }
    },
    "sideEffectsDialog": {
      "title": "Details",
      "detailsForDoctor": "Details for doctor",
      "futureActions": "Future actions",
      "doctorChecked": "Doctor checked",
      "writeCommentPlaceholder": "Write a comment here"
    },
    "prescription": {
      "title": "Prescription",
      "prescriptionStock": "Prescription Stock",
      "doctorCommentTitle": "Doctor comment entry",
      "confirmCreatingPrescriptionTitle": "Do you really want to prescribe these medicines？",
      "inStock": "in stock",
      "takeMedicinesEverySecondDay": "FINA/DUTA used every second day",
      "deselectAll": "DESELECT ALL MEDICINES",
      "doctorCommentPlaceholder": "Please enter a comment",
      "createPrescriptionButton": "CREATE",
      "noPrescriptionMessage": "There is no prescription created for this appointment",
      "orderExpiredMessage": "The order is expired.",
      "manualCancellationMessage": "The prescription was canceled manually",
      "previouslyPrescribedMedicines": "Previously prescribed medicines:",
      "cancelPrescription": "Are you sure you want to cancel the prescription?"
    },
    "patientStockFromAnotherClinic": {
      "clinicAppointmentMessage": "You cannot handover the medicine from this stocks because these medicines were prescribed in the different branch before. If you want to handover the medicine now, please remove this stock and prescribe again in this appointment.",
      "onlineAppointmentMessage": "This patient already has the medicine stocks in other clinic. If you want to register the prescription from this clinic, please reset the medicine stocks the patient already has. * The medicine stocks that are prescribed from 2 clinics cannot exist at the same time.",
      "prescriptionStockIn": "Prescription stock in:"
    },
    "order": {
      "title": "Order",
      "nonPrescribableMedicine": "Non prescribable medicines",
      "clearMedicineStock": "CLEAR MEDICINE STOCK",
      "confirmCreatingOrderTitle": "Do you really want to create this order? <br> If it's an online appointment, a notification will be sent to the patient immediately",
      "confirmPaymentOrderTitle": "Will you complete payment and delivery of this medicine for this time? *After completing prescription. You can not modify medicine for this time.",
      "confirmBankPaymentOrderTitle": "Have you confirmed the bank payment? Once you confirm, this medicine information will be shown in the delivery order.",
      "confirmExpiredOrderTitle": "Do you really want to manually expire this order?",
      "combinedPaymentDialogTitle": "Please enter the amount of each payment method",
      "amountPaidWithCash": "Amount paid with cash",
      "amountPaidWithCreditCard": "Amount paid with credit card",
      "amountPaidWithElectronicMoney": "Amount paid with electronic money",
      "amountRequired": "Amount required is",
      "wayOfPayment": "The way of payment",
      "cashAmount": "Cash",
      "creditCardAmount": "Credit Card",
      "electronicMoneyAmount": "Electronic Money",
      "viewInOrdersList": "View in patient orders List",
      "noOrderMessage": "There are no orders created for this appointment",
      "orderExpiredMessage": "The order is expired.",
      "manualCancellationMessage": "The order was canceled manually",
      "refunded": "Refunded",
      "refundCreatedOn": "Refund created on:",
      "customRefundAmount": "Custom refund amount:",
      "totalRefundAmount": "Total refund amount:",
      "deliveryCost": "Delivery cost",
      "cancelOrder": "Are you sure you want to cancel the order?",
      "discountSection": {
        "applyDiscountQuestion": "Do you want to apply any discount?",
        "useOnlinePrices": "Use online price",
        "promoCode": "Promo code",
        "deduction": "Deduction",
        "deductionOnTotalPrice": "Deduction on total price (¥)",
        "deductionExplanation": "*Deduction is always calculated on the total price. If there is a promotional code applied, the deduction will be calculated on the reduced price.",
        "applyCode": "APPLY",
        "totalDiscount": "Total Discount",
        "totalPriceWithDiscount": "Total price with discount",
        "refund": "REFUND",
        "discount": "Discount",
        "invalidCode": "Invalid code"
      }
    },
    "examinationNoteCancelChangesConfirmationDialog": {
      "unsavedChangesText": "Do you want to exit without saving changes to the examination contents?"
    }
  },
  "medicine": {
    "name": "Medicine name",
    "quantity": "Quantity",
    "remainingQuantity": "Remaining Quantity",
    "price": "Price",
    "discountPrice": "Discount price",
    "onlinePrice": "Online price",
    "confirmSubmit": "Change medication settings. If you change the settings of your regular mail, it will affect the regular mail of existing patients. Are you sure you want to change your settings?"
  },
  "mediaLibrary": {
    "mediaLibrary": "Media library",
    "title": "Title",
    "date": "Date",
    "time": "Time",
    "emptyMediaLibraryMessage": "The media library is empty",
    "addFile": "ADD FILE",
    "edit": "Edit",
    "delete": "Delete",
    "editTitleMessage": "Do you want to edit the title?",
    "editingCompleteMessage": "Title editing is complete",
    "deleteMediaMessage": "Are you sure you want to delete this media?",
    "deleteSuccessMessage": "File deleted",
    "patientUploadedPhotos": "The patient uploaded images for the appointment on {{value.date}} ({{value.numberOfPhotos}})",
    "documents": "Documents",
    "letterOfReference": {
      "letterOfReference": "Reference Letter",
      "createNewLetterOfReference": "Create new reference letter",
      "title": "Title",
      "edit": "Edit",
      "delete": "Delete",
      "emptyLetterOfReferenceList": "There are no reference letters",
      "letterOfReferenceCreated": "Letter of reference created",
      "letterOfReferenceNotCreated": "Letter of reference not created",
      "letterOfReferenceUpdated": "Letter of reference updated",
      "letterOfReferenceNotUpdated": "Letter of reference not updated",
      "approved": "Approved by doctor",
      "close": "Close",
      "submit": "Save",
      "shouldApproveLetterOfReference": "Are you sure you want to approve this letter of reference?",
      "shouldDisapproveLetterOfReference": "Are you sure you want to disapprove this letter of reference?",
      "doctorName": "Doctor name",
      "patientName": "Patient name",
      "institutionName": "Institution name",
      "docSignature": "Doctor",
      "issuingHospitalAddress": "Address location of referral medical institution",
      "issuingHospitalName": "Issuing hospital name",
      "phoneNumber": "Phone number",
      "faxNumber": "Fax number",
      "nameSuffix": "Mr",
      "purposeOfReferring": "Purpose of referring",
      "nameOfDisease": "Name of disease",
      "treatmentResults": "Symptom progression · Treatment course · Test results"
    }
  },
  "errors": {
    "genericError": "Something went wrong, please try to refresh",
    "newApplicationVersion": "New application version is available, please refresh this page or we will do it automatically after 10 seconds...",
    "schedulingNonWorkingDayWithAppointments": "The selected date has scheduled appointments",
    "schedulingNonWorkingDayForClinicAlreadyExists": "Non working day for the selected day already exists",
    "schedulingReservationOutsideWorkShift": "The reservation is outside the employees working hours",
    "schedulingOverlappingReservation": "The reservation overlaps an existing reservation",
    "schedulingReservationInPast": "The reservation can not be scheduled in the past",
    "schedulingArgumentsInvalid": "The reservation has invalid parameters",
    "schedulingWorkShiftDurationShort": "Work shift starts after end time",
    "schedulingWorkShiftInactive": "The selected work shift is inactive",
    "schedulingShortenWorkShiftAppointmentStarted": "Work shift has scheduled appointments in the future",
    "schedulingWorkShiftAssignedInPresentOrFuture": "Work shift cannot be deactivated because it is assigned to a current or future employee schedule",
    "invalidFileSelected": "The file you selected is not a valid type",
    "orderManagementMedicineNotAvailableInStock": "This order contains a medicine amount that is not available in the clinic stock",
    "orderManagementMedicineUsedInOrders": "Medicine is used in orders",
    "notificationPatientMissingDeviceType": "The patient is not logged in",
    "notificationPatientMissingDeviceToken": "The patient is not logged in",
    "notificationPatientExpiredDeviceToken": "The patient is not logged in",
    "notificationInThePast": "The notification has already been sent",
    "medicalEmailPatientAlreadyExists": "A patient with this email already exists",
    "required": "Required fields",
    "minimumLength": "Minimum length is 8",
    "invalidEmail": "Invalid email format",
    "userExists": "The user already exists",
    "invalidDate": "The date is in the past",
    "uniqueCouponCode": "A coupon with the same code already exists",
    "orderManagementCouponRestricted": "Invalid coupon",
    "loginFailed": "Login failed",
    "loginInvalid": "Invalid credentials",
    "tokenExpired": "Token expired",
    "phoneInvalid": "Invalid phone number",
    "medicalAppointmentFinished": "Appointment is already finished",
    "medicalAppointmentNotStarted": "Appointment start time is in the future",
    "medicalAppointmentMissingExamination": "Please finish this appointment's examination at medical record",
    "medicalAppointmentExaminationFinished": "This appointment's examination is finished",
    "patientHasUnpaidOrders": "This patient has an unpaid order",
    "orderManagementMedicineUsedInMedicineStock": "This medicine is already in delivery order, or a patient stock. If you need to change the status, then please create a new one, and set this medicine to inactive.",
    "medicalRecordPatientAlreadyMarkedForDelete": "This patient is currently being deleted",
    "medicalPatientNotFound": "This patient does not exist",
    "workShift": {
      "WorkShiftStarted": "The work shift already started.",
      "ExistingDailyScheduleFound": "An existing daily schedule was found for this user. Please refresh the table.",
      "DailyScheduleNotFound": "The daily schedule for this employee was not found.",
      "DailyScheduleAppointmentsStarted": "The schedule for this employee contains a started appointment.",
      "ClinicNonWorkingDay": "The day is a non working day.",
      "WorkShiftTooShort": "This work shift is too short. Select a longer work shift.",
      "WorkShiftOnWrongDay": "This work shift is on a wrong day.",
      "WorkShiftNotAvailableOnDay": "This work shift is not available for this day.",
      "WorkShiftOutsideWorkingHours": "This work shift is outside working hours.",
      "WorkShiftConflictsWithAppointments": "This work shift conflicts with the employees appointments.",
      "LunchBreakConflictsWithAppointments": "The lunch breaks on this work shft conflicts with the employees appointments.",
      "BreakInThePast": "The assigned lunch break is in the past"
    }
  },
  "nothingFound": {
    "title": "Nothing found"
  },
  "supervisorDoctor": "Supervisor doctor",
  "refund": {
    "title": "Procedure for refund",
    "subtitle": "Please select medicine to refund or enter amount",
    "selectMedicines": "Select medicines to be refunded",
    "medicineName": "Medicine name",
    "price": "Price",
    "amount": "Amount",
    "refundAmount": "Refund amount",
    "totalRefundAmount": "Total refund amount",
    "refundCostOfDelivery": "Refund cost of delivery",
    "enterAmount": "Enter the contents - amount",
    "contents": "Contents",
    "totalRefundAmountLabel": "Total refund amount (¥)",
    "refundableAmount": "Refundable amount",
    "itemsToBeChecked": "Items to be checked for refund procedure",
    "inCaseOfBank": "In the case of bank transfer",
    "pressRefundButton": "Please press the Refund button after the transfer to the bank account is completed.",
    "clinicReservation": "Clinic reservation",
    "pushRefundButton": "Please push the Refund button after the direct refund to the patient is completed.",
    "onlineReservation": "Online reservation · Credit card payment",
    "completeOperation": "When you complete the operation on this page, you will automatically be transferred from Stripe to your credit card a refund will be made."
  },
  "roomSchedules": {
    "title": "Room Schedules",
    "today": "Today",
    "gridView": "Grid View",
    "calendarView": "Calendar View",
    "clinicRoomEvent": "Room Event",
    "deleteRoomEventConfirmation": "Are you sure you want to delete this room event?",
    "minutes": "minutes",
    "finished": "Finished",
    "createRoomEvent": "Create Room Event",
    "editRoomEvent": "Edit Room Event",
    "editRoomAppointment": "Edit Room Appointment",
    "counselorNamePrefix": "カ",
    "writeCommentHere": "Write a comment here",
    "emptyRoom": "Empty Room",
    "unknownRooms": "Unknown Rooms",
    "finishEventConfirmation": "Are you sure you want to finish this event?",
    "refreshPageConfirmation": "There are changes by other people. Please refresh this screen."
  },
  "connectionStatus": {
    "Connected": "CONNECTED",
    "Reconnected": "RECONNECTED, PLEASE REFRESH",
    "Disconnected": "DISCONNECTED"
  },
  "noteForConcierge": {
    "title": "Note for concierge"
  },
  "callScreen": {
    "callPatient": "CALL PATIENT",
    "phoneNumber": "Phone number",
    "outgoing": "OUTGOING",
    "patientId": "ID",
    "sendNotification": "SEND NOTIFICATION",
    "hangUp": "HANG UP",
    "patientUnavailable": "The patient is not available",
    "callDeclined": "The patient declined the call",
    "networkConnectionProblems": "The patient is having network connection problems. the call may reconnect automatically",
    "genericPatientDisconnect": "The patient is disconnected",
    "ongoingCall": "ONGOING CALL",
    "selectImagesToCompare": "Select images to compare",
    "today": "Today",
    "top": "Top",
    "right": "Right",
    "left": "Left",
    "headskin1": "Headskin1",
    "headskin2": "Headskin2",
    "headskin3": "Headskin3",
    "temporaryStorage": "Temporary storage",
    "sharedScreen": "Shared Screen",
    "headPosition": {
      "Top": "Top",
      "Left": "Left",
      "Right": "Right",
      "Other": "Other",
      "Headskin1": "Headskin1",
      "Headskin2": "Headskin2"
    },
    "unsavedScreenShotsDialog": {
      "endCall": "You haven’t saved any images yet! Are you sure you want to proceed with ending the call without saving? All the images from temporary storage will be lost.",
      "cancel": "You haven’t saved any images yet! Are you sure you want to proceed with canceling the images upload? All the images from temporary storage will be lost."
    },
    "uploadImages": "UPLOADING IMAGES:",
    "openTokError": {
      "OT_STREAM_DESTROYED": "The subscriber stream was destroyed before it could be subscribed to. Please refresh and try again",
      "OT_CHROME_MICROPHONE_ACQUISITION_ERROR": "The browser cannot access your microphone. You must restart the browser and reload the page!",
      "OT_MEDIA_ERR_NETWORK": "A network error occurred. Please check your internet connection!",
      "OT_NO_DEVICES_FOUND": "You don't have camera or microphone on this computer, so the video call can not be established.",
      "OT_MEDIA_ERR_ABORTED": "You don't have camera or microphone on this computer, so the video call can not be established.",
      "OT_NOT_CONNECTED": "Couldn't perform action because you are not connected to the network.",
      "OT_USER_MEDIA_ACCESS_DENIED": "You denied access to camera or microphone. Please click allow camera and microphone and restart the page!",
      "SOMETHING_WENT_WRONG": "Something went wrong, we cannot establish a video call. Please contact your administrator!",
      "genericError": "An error occurred. please try again",
      "OT_TIMEOUT": "An error occurred. please try again",
      "OT_CONNECT_FAILED": "Failed to connect to session",
      "OT_UNEXPECTED_SERVER_RESPONSE": "This indicates an unexpected error from the OpenTok server",
      "OT_HARDWARE_UNAVAILABLE": "Your camera or microphone is not available",
      "audioPermissionsNotGranted": "This patient seems to have disabled the permissions for the camera/mic so he can join the call, hear your voice, see video of you, but can not transmit the video/audio to you",
      "videoPermissionsNotGranted": "This patient seems to have disabled the permissions for the camera/mic so he can join the call, hear your voice, see video of you, but can not transmit the video/audio to you",
      "onHold": "The patient has another call that's currently active and this call is currently paused. Please wait for the patient to finish the current call and join again."
    },
    "imageBoard": {
      "clickBlockDescription": "CLICK THIS BLOCK TO SELECT A CAPTURE",
      "selectLeftImageDescription": "SELECT CAPTURE ON THE LEFT (UPPER)",
      "selectRightImageDescription": "SELECT CAPTURE ON THE RIGHT (LOWER)",
      "leftUpper": "LEFT (UPPER)",
      "rightLower": "RIGHT (LOWER)"
    }
  },
  "fileUploadMessages": {
    "failedUpload": "The file could not be uploaded. Please try again.",
    "completedUpload": "Upload completed",
    "wantToUploadFile": "Would you like to upload this file?"
  },
  "appointmentImages": {
    "mark": "Are you sure you want to mark this appointment?",
    "unmark": "Are you sure you want to unmark this appointment?",
    "uploadCompleted": "Upload completed",
    "uploadFailed": "Some file could not be uploaded. Please try again."
  },
  "shipment": {
    "title": "Shipment",
    "shipmentSelected": "shipments selected",
    "selectAll": "Select all",
    "show": "Show",
    "hide": "Hide",
    "noAppointment": "No appointment orders",
    "noShipment": "There are no shipments for the selected clinic",
    "maxShipmentsSelection": "shipments can be selected only",
    "shipmentCompleteDialogTitle": "Did you complete the following medication shipment for this time?",
    "shipmentCsvDownloadError": "An error occured while downloading the shipment",
    "table": {
      "orderNo": "Order No.",
      "reservationTime": "Date and time of reservation",
      "patientName": "Patient name",
      "alternativeName": "Alternative name",
      "address": "Address",
      "phoneNumber": "Phone number",
      "medicines": "Medicines",
      "prescription": "Prescription",
      "medicineName": "Medicine name",
      "amount": "Amount",
      "price": "Price",
      "totalPrice": "Total price",
      "wayOfPayment": "Way of payment",
      "firstOnlineDelivery": "First online delivery"
    },
    "shipmentErrorStatuses": {
      "AlreadyShipped": "Order is already shipped",
      "NotPaid": "Order status is not paid or free confirmed"
    },
    "print": {
      "createdOn": "Created on:",
      "todayDeliveredMedicine": "Today's delivered medicine",
      "prescription": "Prescription",
      "processingSide": "Processing side",
      "deliveredContent": "Delivered content",
      "discount": "Discount",
      "deduction": "Deduction",
      "totalPrice": "Total price",
      "dispensingDate": "Dispensing date"
    }
  },
  "trackingNumberList": {
    "trackingNumberSentList": "Tracking number sent list",
    "trackingNumber": "Tracking number",
    "dateTimeSent": "Time email was sent",
    "noTrackedOrders": "No tracked orders"
  },
  "trackingNumberRequestedList": {
    "title": "Tracking number requested list",
    "itemsSelected": "items selected",
    "selectAll": "Select all",
    "enter": "Enter",
    "noAppointment": "No appointment delivery",
    "noRequestedTrackOrders": "No orders that is requested tracking number for them",
    "mailTrackingNumberDialogTitle": "Do you really want to send these patients their tracking number?",
    "table": {
      "orderNo": "Order No.",
      "reservationTime": "Examination/Consultation date and time",
      "patientName": "Patient name",
      "alternativeName": "Alternative name",
      "address": "Address",
      "phoneNumber": "Phone number",
      "trackingNumber": "Tracking number"
    },
    "sendMailErrors": {
      "MissingPatientEmail": "Patient does not have email",
      "PatientDidNotRequestTrackingNumber": "Patient did not request tracking number for this order",
      "MissingTrackingNumber": "This order does not have tracking number",
      "Other": "For some reason this tracking number can not be sent"
    }
  },
  "weeklyShippedMedicines": {
    "title": "Weekly shipped medicines",
    "numberOfDeliveredMedicines": "Number of medicines delivered to patients",
    "total": "Total"
  },
  "orderStatistics": {
    "paidWithCreditCard": "Paid with credit card",
    "paidWithElectronicMoney": "Paid with electronic money",
    "paidWithCash": "Paid with cash",
    "paidInBank": "Paid via bank",
    "refund": "Refund",
    "totalPrice": "Total price",
    "trackingRequested": "Tracking number requested list",
    "trackingSent": "Tracking number sent list",
    "noMedicinesAndPaymentInfo": "There are no shipped medicines for the selected clinic and day",
    "noAppointmentOrder": "No appointment was made for this order",
    "table": {
      "appointmentStartingTime": "Appointment starting time",
      "patientName": "Patient name",
      "medicineName": "Delivery medicine name",
      "amount": "Amount",
      "totalPrice": "Total price",
      "paymentMethod": "Way of payment"
    }
  },
  "orders": {
    "title": "Unpaid Orders",
    "waitingClinicPayment": "List of clinic orders pending payment completion",
    "waitingOnlinePayment": "List of online orders pending bank transfer confirmation",
    "patientName": "Patient Name",
    "orderDate": "Order Date",
    "price": "Price",
    "actions": "Actions",
    "tos": "TOS",
    "print": "Print",
    "links": "Links",
    "showOrdersForExpiry": "show only orders that should be expired",
    "unacceptedTOS": "UNACCEPTED TOS",
    "shouldBeExpired": "Should be expired",
    "confirmCashPayment": "CASH",
    "confirmFreePayment": "FREE",
    "confirmBankPayment": "BANK",
    "confirmCardPayment": "CARD",
    "confirmElectronicMoney": "E-MONEY",
    "combinedPayment": "COMBINED",
    "expire": "EXPIRE",
    "noOrdersPendingPayment": "There are no orders pending payment",
    "searchOnlineOrders": "Search with Patient Name (Hiragana・kanji character)"
  },
  "bloodAppointments": {
    "time": "Time",
    "location": "Location",
    "roomName": "Room name",
    "name": "Name",
    "alternativeName": "Alternative name",
    "gender": "Gender",
    "dateOfBirthAge": "Date of birth (age)",
    "commentForNurse": "Comment for nurse",
    "noBloodAppointments": "There are no blood examination appointments for the selected day in this clinic"
  },
  "orderType": {
    "Pickup": "Pickup",
    "Delivery": "Delivery"
  },
  "appointmentEvent": {
    "patientCondition": "PC",
    "lastOrderExpired": "D-Exp",
    "bloodExaminationStatus": "BS"
  },
  "allSalons": {
    "viewAllSalons": "View all salons",
    "exitAllSalons": "Exit all salons"
  },
  "patientRegistration": {
    "title": "Patient Registration",
    "recordsSelected": "records selected",
    "applicationDateAndTime": "Application Date/Time",
    "desiredPeriodForFirstAppointment": "First Appointment Period",
    "preferredAppointmentLocation": "Preferred Appointment Location",
    "patientName": "Patient Name(s)",
    "patientContact": "Patient Contact(s)",
    "patientAffiliateId": "Patient Affiliate ID",
    "patientRegisteredStatus": "Patient Registered",
    "phoneCallStatus": "Phone Call Status",
    "notes": "notes",
    "linkToPatientDetails": "Patient Details",
    "noPatient": "No patients have registered",
    "memo": "Memo",
    "allMemos": "All memos",
    "addMemo": "Add memo",
    "deleteMemoConfirmation": "Are you sure you want to delete this memo?",
    "desiredConsultationPeriod": {
      "Today": "Today",
      "TomorrowOrAfterward": "Tomorrow or afterward"
    },
    "contactStatus": {
      "NotSet": "Not Set",
      "CalledOneTimeDidNotPickUp": "Called once, didn’t pickup",
      "CalledTwoTimesDidNotPickUp": "Called twice, didn’t pickup",
      "CalledThreeTimesDidNotPickUp": "Called three times, didn’t pickup",
      "Other": "Other",
      "WaitingForPhoneCall": "Waiting for return phone call",
      "ReservationDone": "Reservation Done",
      "OneWeekCancel": "One week Cancel",
      "Cancel": "Cancel",
      "NoCount": "No Count"
    },
    "contactStatusFilter": {
      "Incomplete": "Incomplete",
      "Canceled": "Canceled",
      "Complete": "Complete",
      "NoCount": "NoCount"
    },
    "error": {
      "PhoneNumberAlreadyUsed": "This phone number belongs to a registered patient",
      "EmailAlreadyUsed": "This email belongs to a registered patient"
    }
  },
  "medicineCategory": {
    "FINA": "FINA",
    "DUTA": "DUTA",
    "MINOCK": "MINOCK",
    "MINOCK_LIQUID": "MINOCK LIQUID",
    "PANTO": "PANTO",
    "OTHER": "OTHER"
  }
}
